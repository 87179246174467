define("@dwind/selfcare/templates/components/selfcare-help-faq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uYmurVAs",
    "block": "{\"symbols\":[\"accordion\",\"faq\",\"element\",\"@onFaqExpand\",\"@faqs\"],\"statements\":[[5,\"widi-accordion\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[22,5,[]]],null,{\"statements\":[[0,\"    \"],[6,[22,1,[\"element\"]],[],[[\"@onExpand\",\"@classNames\"],[[27,\"action\",[[22,0,[]],[22,4,[]],[22,2,[\"title\"]]],null],\"selfcare-help-accordion selfcare-help-accordion--faq\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"selfcare-help-accordion__single selfcare-help-accordion__single--faq \",[27,\"if\",[[22,3,[\"expanded\"]],\"wwind-help_accordion--expanded\"],null]]]],[9],[0,\"\\n        \"],[7,\"i\"],[9],[10],[0,\"\\n        \"],[7,\"img\"],[11,\"class\",\"selfcare-help-accordion__image selfcare-help-accordion__image--faq\"],[12,\"src\",[22,2,[\"image\"]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n        \"],[7,\"b\"],[11,\"class\",\"selfcare-help-accordion__title selfcare-help-accordion__title--faq\"],[9],[1,[22,2,[\"title\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[4,\"if\",[[22,3,[\"expanded\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"selfcare-help-accordion__description selfcare-help-accordion__description--faq\"],[9],[0,\"\\n          \"],[1,[22,2,[\"description\"]],true],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/selfcare/templates/components/selfcare-help-faq.hbs"
    }
  });

  _exports.default = _default;
});