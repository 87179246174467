define("@dwind/selfcare/router-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'ftth': {
      url: 'help.fixed.ftth',
      external: false
    },
    'ftth-fp': {
      url: 'help.fixed.ftth',
      external: false
    },
    'ftth-onestep': {
      url: 'help.fixed.ftth-onestep',
      external: false
    },
    'ftth-attivo': {
      url: 'help.fixed.ftth-attivo',
      external: false
    },
    'ftth-ftth': {
      url: 'help.fixed.ftth-ftth',
      external: false
    },
    'ftth-ull-iso': {
      url: 'help.fixed.ftth-ull-iso',
      external: false
    },
    'ftth-ull-iso-fp': {
      url: 'help.fixed.ftth-ull-iso-fp',
      external: false
    },
    'ftth-ull-iso-onestep': {
      url: 'help.fixed.ftth-ull-iso-onestep',
      external: false
    },
    'fttc-ull': {
      url: 'help.fixed.fttc-ull',
      external: false
    },
    'fttc-ull-lna': {
      url: 'help.fixed.fttc-ull-lna',
      external: false
    },
    'fttc-ull-lna-np': {
      url: 'help.fixed.fttc-ull-lna-np',
      external: false
    },
    'fttc-ull-la': {
      url: 'help.fixed.fttc-ull-la',
      external: false
    },
    'fwa': {
      url: 'help.fixed.fwa',
      external: false
    },
    'f003': {
      url: 'https://www.google.it',
      external: true
    }
  };
  _exports.default = _default;
});