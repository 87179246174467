define("@dwind/selfcare/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JDreo24c",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\"],[9],[1,[23,[\"model\",\"title\"]],false],[10],[0,\"\\n\"],[7,\"link\"],[11,\"rel\",\"apple-touch-icon\"],[11,\"sizes\",\"180x180\"],[12,\"href\",[27,\"asset-map\",[[27,\"concat\",[\"assets/@dwind/selfcare/favicons/\",[23,[\"model\",\"brand\"]],\"/apple-touch-icon.png\"],null]],null]],[9],[10],[0,\"\\n\"],[7,\"link\"],[11,\"rel\",\"icon\"],[11,\"sizes\",\"32x32\"],[12,\"href\",[27,\"asset-map\",[[27,\"concat\",[\"assets/@dwind/selfcare/favicons/\",[23,[\"model\",\"brand\"]],\"/favicon-32x32.png\"],null]],null]],[11,\"type\",\"image/png\"],[9],[10],[0,\"\\n\"],[7,\"link\"],[11,\"rel\",\"icon\"],[11,\"sizes\",\"16x16\"],[12,\"href\",[27,\"asset-map\",[[27,\"concat\",[\"assets/@dwind/selfcare/favicons/\",[23,[\"model\",\"brand\"]],\"/favicon-16x16.png\"],null]],null]],[11,\"type\",\"image/png\"],[9],[10],[0,\"\\n\"],[7,\"link\"],[11,\"rel\",\"icon\"],[12,\"href\",[27,\"asset-map\",[[27,\"concat\",[\"assets/@dwind/selfcare/favicons/\",[23,[\"model\",\"brand\"]],\"/favicon.ico\"],null]],null]],[9],[10],[0,\"\\n\"],[7,\"link\"],[11,\"rel\",\"manifest\"],[12,\"href\",[27,\"asset-map\",[[27,\"concat\",[\"assets/@dwind/selfcare/favicons/\",[23,[\"model\",\"brand\"]],\"/selfcare.webmanifest\"],null]],null]],[9],[10],[0,\"\\n\"],[7,\"link\"],[11,\"rel\",\"mask-icon\"],[12,\"href\",[27,\"asset-map\",[[27,\"concat\",[\"assets/@dwind/selfcare/favicons/\",[23,[\"model\",\"brand\"]],\"/safari-pinned-tab.svg\"],null]],null]],[11,\"color\",\"#5bbad5\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"name\",\"msapplication-TileColor\"],[11,\"content\",\"#da532c\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"name\",\"theme-color\"],[11,\"content\",\"#ffffff\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/selfcare/templates/head.hbs"
    }
  });

  _exports.default = _default;
});