define("@dwind/selfcare/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D7omRMn7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"selfcare-error selfcare__content--header--footer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"selfcare-error__content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"selfcare-error__title__main\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"selfcare.error.generic_title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"selfcare-error__details\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"selfcare-error__details__title\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"selfcare.error.check_other_offers_slogan\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"tabindex\",\"0\"],[12,\"href\",[21,\"brandUrl\"]],[11,\"class\",\"selfcare-error__action\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"selfcare.error.check_other_offers\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/selfcare/templates/error.hbs"
    }
  });

  _exports.default = _default;
});