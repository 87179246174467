define("@dwind/selfcare/services/selfcare-channel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    fastboot: Ember.inject.service(),
    brand: 'windtre',
    partner: Ember.computed('brand', {
      get: function get() {
        switch (this.brand) {
          default:
            return 'windtre';
        }
      }
    }),
    brandUrl: Ember.computed('brand', {
      get: function get() {
        switch (this.brand) {
          default:
            return 'https://www.windtre.it/';
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var hostname;

      if (Ember.get(this, 'fastboot.isFastBoot')) {
        hostname = Ember.get(this, 'fastboot.request.host');
      } else {
        hostname = document.location.host;
      }

      if (!Ember.isEmpty(hostname)) {
        var brand = hostname.split('.')[1];

        if (!Ember.isEmpty(brand)) {
          Ember.set(this, 'brand', brand);
        }
      }
    }
  });

  _exports.default = _default;
  48;
});