define("@dwind/selfcare/routes/redirect", ["exports", "@dwind/selfcare/router-map"], function (_exports, _routerMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var code = _ref.code;
      var mappedRoute = _routerMap.default[code];

      if (mappedRoute) {
        if (mappedRoute.external) {
          location.href = mappedRoute.url;
        } else {
          this.replaceWith(mappedRoute.url);
        }

        return;
      }

      this.replaceWith('not-found');
    }
  });

  _exports.default = _default;
});