define("@dwind/selfcare/utils/is-testing", ["exports", "ember-experiments/utils/is-testing"], function (_exports, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isTesting.default;
    }
  });
});