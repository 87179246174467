define("@dwind/selfcare/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OuXQuT6k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"id\",\"selfcare-home\"],[11,\"class\",\"selfcare-help\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"selfcare-help-index__container\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"selfcare-page-title\"],[9],[0,\"404\"],[10],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Pagina non trovata\"],[10],[0,\"\\n    \"],[7,\"h3\"],[9],[0,\"Ci dispiace, la pagina che hai richiesto non esiste.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[0,\"  \"],[5,\"selfcare-promo-banner-mobile\",[],[[\"@brand\"],[[21,\"brand\"]]]],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/selfcare/templates/index.hbs"
    }
  });

  _exports.default = _default;
});