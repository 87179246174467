define("@dwind/selfcare/components/selfcare-help-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'selfcare-help-steps__mainContainer'
  });

  _exports.default = _default;
});