define('@dwind/selfcare/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('@dwind/selfcare');
} else {

          var exports = {
            'default': {"modulePrefix":"@dwind/selfcare","environment":"production","rootURL":"/","locationType":"auto","ifa":{"enabled":true,"inline":true},"EmberENV":{"FEATURES":{},"EXTEND_PROTOTYPES":{"Date":false},"_APPLICATION_TEMPLATE_WRAPPER":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true},"APP":{"name":"@dwind/selfcare","version":"1.5.10+d5dfc92f"},"metricsAdapters":[{"name":"GoogleTagManager","config":{"id":"GTM-PNL5CDC"}}],"apiHost":"https://api.shop.windtre.it","fastboot":{"hostWhitelist":["selfcaring.windtre.it","selfcaring.wind.it","selfcaring.tre.it"]},"sentry":{"dsn":"https:///0e204f6f15c04a95be7f583708d80aa2@api.shop.windtre.it/__sentry/47","whitelistUrls":[{}]},"exportApplicationGlobal":false}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
