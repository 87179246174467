define("@dwind/selfcare/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    headData: Ember.inject.service(),
    selfcareChannel: Ember.inject.service(),
    brand: Ember.computed.readOnly('selfcareChannel.brand'),
    title: function title() {
      return Ember.String.capitalize(this.brand);
    },
    beforeModel: function beforeModel() {
      return this.intl.setLocale(['it-it']);
    },
    afterModel: function afterModel() {
      Ember.set(this, 'headData.brand', this.brand);
    }
  });

  _exports.default = _default;
});