
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('@dwind/selfcare/app')['default'].create({"name":"@dwind/selfcare","version":"1.5.10+d5dfc92f"});
  }
}

define('~fastboot/app-factory', ['@dwind/selfcare/app', '@dwind/selfcare/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

