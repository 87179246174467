define("@dwind/selfcare/templates/help/mobile/old/tre/prepay-untied-no-mnp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WHxKEt9k",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/selfcare/templates/help/mobile/old/tre/prepay-untied-no-mnp.hbs"
    }
  });

  _exports.default = _default;
});