define("@dwind/selfcare/routes/help/mobile/prepay-tied-port-in", ["exports", "@dwind/selfcare/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    intl: Ember.inject.service(),
    selfcareChannel: Ember.inject.service(),
    brand: Ember.computed.readOnly('selfcareChannel.brand'),
    beforeModel: function beforeModel() {
      this.transitionTo("help.mobile.".concat(this.brand, ".prepay-tied-port-in"));
    }
  });

  _exports.default = _default;
});