define("@dwind/selfcare/routes/index", ["exports", "@dwind/selfcare/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    intl: Ember.inject.service(),
    selfcareChannel: Ember.inject.service(),
    brand: Ember.computed.readOnly('selfcareChannel.brand')
  });

  _exports.default = _default;
});