define("@dwind/selfcare/templates/help/mobile/old/tre/prepay-tied-port-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MaRdhD9q",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/selfcare/templates/help/mobile/old/tre/prepay-tied-port-in.hbs"
    }
  });

  _exports.default = _default;
});