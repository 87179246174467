define("@dwind/selfcare/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    cookies: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    metrics: Ember.inject.service(),
    selfcareChannel: Ember.inject.service(),
    brand: Ember.computed.readOnly('selfcareChannel.brand'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'fastboot.isFastBoot')) {
        window.addEventListener('scroll', this._handleScroll.bind(this));
        document.body.addEventListener('click', this._handleScroll.bind(this), true);
      }

      if (this.brand == 'tre') {
        var cookiesAcceptedtre = this.cookies.read("cookiesAccepted".concat(this.brand)) === 'true';
        var currentCookie = 'tre';
        Ember.set(this, 'currentCookie', currentCookie);
        Ember.set(this, "cookiesAccepted".concat(this.brand), cookiesAcceptedtre);
        Ember.set(this, "metrics.context.cookiesAccepted".concat(this.brand), cookiesAcceptedtre);
      }

      if (this.brand == 'wind') {
        var cookiesAcceptedwind = this.cookies.read("cookiesAccepted".concat(this.brand)) === 'true';
        var _currentCookie = 'wind';
        Ember.set(this, 'currentCookie', _currentCookie);
        Ember.set(this, "cookiesAccepted".concat(this.brand), cookiesAcceptedwind);
        Ember.set(this, "metrics.context.cookiesAccepted".concat(this.brand), cookiesAcceptedwind);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentRouter', this.currentRouteName);
    },
    _handleScroll: function _handleScroll() {
      this.acceptCookies();
      window.removeEventListener('scroll', this._handleScroll.bind(this));
      window.removeEventListener('click', this._handleScroll.bind(this));
    },
    acceptCookies: function acceptCookies() {
      if (!Ember.get(this, 'fastboot.isFastBoot') && !this.cookiesAccepted) {
        Ember.set(this, "cookiesAccepted".concat(this.brand), true);
        this.cookies.write("cookiesAccepted".concat(this.brand), 'true');
        Ember.set(this, "metrics.context.cookiesAccepted".concat(this.brand), true);
        this.metrics.trackEvent({
          event: 'acceptCookies'
        });
      }
    },
    routeName: Ember.computed('currentRouteName', {
      get: function get() {
        return this.currentRouteName.replace(/\./g, '-');
      }
    }),
    actions: {
      acceptCookies: function acceptCookies() {
        this.acceptCookies();
      },
      scrollY: function scrollY() {
        this.acceptCookies();
      }
    }
  });

  _exports.default = _default;
});