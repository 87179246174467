define("@dwind/selfcare/routes/base-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    experiments: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    metrics: Ember.inject.service(),
    selfcareChannel: Ember.inject.service(),
    brand: Ember.computed.readOnly('selfcareChannel.brand'),
    brandUrl: Ember.computed.readOnly('selfcareChannel.brandUrl'),
    brandHost: Ember.computed('brandUrl', {
      get: function get() {
        if (!Ember.get(this, 'fastboot.isFastBoot')) {
          return new URL(this.brandUrl).hostname.replace('www.', '');
        }

        return '';
      }
    }),
    partner: Ember.computed.readOnly('selfcareChannel.partner'),
    afterModel: function afterModel(model, _ref) {
      var _Object$assign;

      var queryParams = _ref.queryParams;
      var variants = this.getVariants();

      for (var name in variants) {
        this.experiments.setup(this._nameForVariant(name), variants[name]);
      }

      var requested = Ember.get(queryParams, 'variants');
      var requestedVariants = requested ? requested.split(',') : [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = requestedVariants[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _variant = _step.value;

          var _variant$split = _variant.split('/'),
              _variant$split2 = _slicedToArray(_variant$split, 2),
              _name2 = _variant$split2[0],
              value = _variant$split2[1];

          if (_name2 in variants && value in variants[_name2]) {
            this.experiments.enable(this._nameForVariant(_name2), value);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var enabledVariants = this.experiments.getExperiments();
      var routeVariants = {};

      for (var variant in enabledVariants) {
        var _name = this._variantForName(variant);

        if (!Ember.isEmpty(_name)) {
          routeVariants[_name] = enabledVariants[variant];
        }
      }

      Ember.set(this, 'variants', routeVariants);
      var consumerComposed = "consumer".concat(Ember.String.capitalize(this.brand));
      var dataLayer = Object.assign(this.getTrackingData(model), (_Object$assign = {
        page: this.routeName,
        partner: this.partner,
        brand: this.brand,
        variants: this.variants,
        event: 'visualSMS',
        area: 'visual sms',
        'level-1': this.getLevel1(),
        'level-2': this.getLevel2(),
        logged: false
      }, _defineProperty(_Object$assign, consumerComposed, 'fisso'), _defineProperty(_Object$assign, "servizioRichiesto", this.getServiceRequested()), _defineProperty(_Object$assign, "offerType", 'fisso'), _defineProperty(_Object$assign, "offerFramework", 'semplice'), _defineProperty(_Object$assign, "tipoLinea", this.getLineType()), _Object$assign));
      this.metrics.trackPage(dataLayer);
    },
    getServiceRequested: function getServiceRequested() {
      var RouteN = this.routeName;
      var serviceFtth = RouteN.includes('ftth');
      var serviceFttc = RouteN.includes('fttc');

      if (serviceFtth) {
        return 'ftth';
      } else {
        if (serviceFttc) {
          return 'fttc-ull';
        } else {
          return '';
        }
      }
    },
    getLevel1: function getLevel1() {
      var RouteN = this.routeName;
      var serviceFtth = RouteN.includes('ftth');
      var serviceFttc = RouteN.includes('fttc');

      if (serviceFtth) {
        return 'la tua fibra';
      } else {
        if (serviceFttc) {
          if (RouteN == 'help.fixed.fttc-ull' || RouteN == 'help.fixed.fttc-ull-la-fp') {
            return 'internet a casa';
          } else {
            return 'internet fino a 200 mega';
          }
        } else {
          return '';
        }
      }
    },
    getLineType: function getLineType() {
      var RouteN = this.routeName;

      switch (RouteN) {
        case 'help.fixed.ftth-ull-iso':
        case 'help.fixed.ftth-ull-iso-onestep':
        case 'help.fixed.fttc-ull':
          return 'migrazione interna';

        case 'help.fixed.fttc-ull-la':
        case 'help.fixed.fttc-ull-lna-np':
          return 'cambio operatore';

        case 'help.fixed.fttc-ull-lna':
        case 'help.fixed.ftth-onestep':
        case 'help.fixed.ftth':
        case 'help.fixed.ftth-fp':
          return 'nuova attivazione';
      }
    },
    getLevel2: function getLevel2() {
      var RouteN = this.routeName;

      switch (RouteN) {
        case 'help.fixed.ftth-ull-iso':
          return 'no one step';

        case 'help.fixed.ftth-ull-iso-onestep':
          return 'one step';

        case 'help.fixed.fttc-ull':
          return 'no one step';

        case 'help.fixed.fttc-ull-la':
          return 'no one step';

        case 'help.fixed.fttc-ull-lna-np':
          return 'no one step - np';

        case 'help.fixed.fttc-ull-lna':
          return 'no one step';

        case 'help.fixed.ftth-onestep':
          return 'one step';

        case 'help.fixed.ftth':
        case 'help.fixed.ftth-fp':
          return 'no one step';
      }
    },
    getTrackingData: function getTrackingData()
    /* model */
    {
      return {};
    },
    getVariants: function getVariants() {
      return {};
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'brand', this.brand);
      Ember.set(controller, 'brandHost', this.brandHost);
      Ember.set(controller, 'brandUrl', this.brandUrl);
      Ember.set(controller, 'variants', this.variants);
    },
    _nameForVariant: function _nameForVariant(name) {
      return Ember.String.camelize("".concat(this.routeName, ".").concat(name));
    },
    _variantForName: function _variantForName(variant) {
      var routeRegexp = new RegExp("^".concat(Ember.String.camelize(this.routeName)), 'gi');

      if (!variant.match(routeRegexp)) {
        return null;
      }

      return variant.replace(routeRegexp, '').toLowerCase();
    }
  });

  _exports.default = _default;
});