define("@dwind/selfcare/router", ["exports", "@dwind/selfcare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    headData: Ember.inject.service(),
    setTitle: function setTitle(title) {
      this.headData.set('title', title);
    }
  });
  Router.map(function () {
    this.route('status', {
      path: '/_status'
    }), this.route('help', function () {
      this.route('devindexlist');
      this.route('fixed', {
        path: '/fisso'
      }, function () {
        this.route('ftth');
        this.route('ftth-fp');
        this.route('ftth-onestep');
        this.route('ftth-ull-iso');
        this.route('ftth-ull-iso-onestep');
        this.route('ftth-ull-iso-fp');
        this.route('fttc-ull');
        this.route('fttc-ull-lna');
        this.route('fttc-ull-lna-np');
        this.route('fttc-ull-la');
        this.route('fttc-ull-la-fp');
        this.route('ftth-ftth');
        this.route('ftth-attivo');
        this.route('fwa');
      });
      this.route('mobile', {
        path: '/mobile'
      }, function () {
        // this.route('postpay-port-in');
        // this.route('postpay-no-mnp');
        // this.route('prepay-tied-port-in');
        // this.route('prepay-tied-no-mnp');
        // this.route('prepay-untied-port-in');
        // this.route('prepay-untied-no-mnp');
        this.route('windtre', {
          path: '/windtre'
        }, function () {
          this.route('prepaid-mnp');
          this.route('prepaid'); // this.route('postpaid');
          // this.route('no-tax');

          this.route('tied-mnp');
          this.route('tied');
        }); // this.route('tre', { path: '/tre' }, function() {
        //   this.route('postpay-port-in');
        //   this.route('postpay-no-mnp');
        //   this.route('prepay-tied-port-in');
        //   this.route('prepay-tied-no-mnp');
        //   this.route('prepay-untied-port-in');
        //   this.route('prepay-untied-no-mnp');
        // });
      });
    });
    this.route('redirect', {
      path: '/:code'
    });
  });
  var _default = Router;
  _exports.default = _default;
});