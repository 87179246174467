define("@dwind/selfcare/templates/help/fixed/ftth-fp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qGwXwocH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"selfcare-help-fixed-ftth\"],[9],[0,\"\\n  \"],[7,\"article\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"selfcare-help__title__container mb-30 mt-40\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"selfcare-help__title\"],[9],[1,[27,\"t\",[\"selfcare.help.fixed.ftth-fp.title\"],null],false],[10],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"selfcare-help__title__image\"],[11,\"src\",\"/assets/@dwind/selfcare/images/icons/ico_fibra.svg\"],[11,\"alt\",\"\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[0,\"    \"],[5,\"selfcare-help-steps\",[],[[\"@steps\"],[[21,\"nextSteps\"]]]],[0,\"\\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"selfcare-help-remember__container\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"selfcare-help-remember__arrow\"],[9],[1,[27,\"t\",[\"selfcare.help.fixed.ftth.remember.arrow_title\"],[[\"htmlSafe\"],[true]]],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"selfcare-help-remember__description\"],[9],[1,[27,\"t\",[\"selfcare.help.fixed.ftth-fp.remember.permission_description\"],[[\"htmlSafe\"],[true]]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[0,\"    \"],[7,\"h3\"],[11,\"class\",\"selfcare-help__title selfcare-help__title--smaller selfcare-help__title--black mb-20 mt-40\"],[9],[1,[27,\"t\",[\"selfcare.help.fixed.ftth.useful_info.title\"],null],false],[10],[0,\"\\n\"],[0,\"    \"],[5,\"selfcare-help-faq\",[],[[\"@faqs\",\"@onFaqExpand\"],[[21,\"navigationFaq\"],[27,\"action\",[[22,0,[]],\"sendEvent\",\"navigationFaqExpand\"],null]]]],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"selfcare-help-spacer\"],[9],[10],[0,\"\\n\"],[0,\"    \"],[5,\"selfcare-promo-banner\",[],[[\"@brand\"],[[21,\"brand\"]]]],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/selfcare/templates/help/fixed/ftth-fp.hbs"
    }
  });

  _exports.default = _default;
});