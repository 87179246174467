define("@dwind/selfcare/components/widi-modal-footer", ["exports", "@dwind/widi-uikit/components/widi-modal-footer"], function (_exports, _widiModalFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiModalFooter.default;
    }
  });
});