define("@dwind/selfcare/controllers/help/fixed/ftth-fp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    actions: {
      sendEvent: function sendEvent(event, title) {
        this.metrics.trackEvent({
          event: event,
          title: title
        });
      }
    }
  });

  _exports.default = _default;
});