define("@dwind/selfcare/translations/it-it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "selfcare": {
      "company": "Wind Tre S.p.A.",
      "cookies": {
        "link": "Informazioni e gestione consenso cookie.",
        "policy": "Questo sito usa cookie, anche di terzi, per migliorare l’esperienza di navigazione e adeguare la pubblicità alle tue preferenze. Chiudendo questo banner o proseguendo nella navigazione acconsenti all’utilizzo di cookie.",
        "tre": {
          "link": "Se vuoi saperne di piu' o negare il consenso a tali cookie clicca qui",
          "policy": "Wind Tre S.p.A. utilizza su questo Sito cookie al fine di personalizzare i contenuti promozionali in base ai potenziali interessi dell'utente. Inoltre, per offrirti una migliore esperienza digitale su questo sito, utilizza cookie di 'terza parte', ovvero cookie statistici di tipo analitico inviati da Google Inc. nell'ambito del servizio di analisi statistiche in forma aggregata denominato Google Analytics. Proseguendo con la navigazione mediante accesso ad altra area del sito o selezione di un elemento dello stesso (ad esempio, di un'immagine o di un link) o cliccando sul tasto 'Accetta' presti il consenso all'uso dei cookie e ci autorizzi a memorizzare e ad accedere a tali cookie sul tuo dispositivo. Se non accetti ti preghiamo di chiudere questa finestra del tuo browser."
        }
      },
      "error": {
        "check_latest_offer": "Scopri la nuova offerta",
        "check_other_offers": "Scopri le altre offerte",
        "check_other_offers_slogan": "Non perdere le altre offerte per te",
        "generic_title": "Oops!",
        "new_mobile_offer_first_line": "Scopri subito la nuova offerta <strong>All Inclusive Limited Edition</strong>",
        "new_mobile_offer_second_line": "o cerca quella che preferisci su",
        "offer_not_available": "L'offerta non è più disponibile",
        "page_does_not_exist": "La pagina che stai cercando non esiste",
        "website_windtre": "windtre.it"
      },
      "help": {
        "fixed": {
          "banner": {
            "tre": {
              "button": "Scopri",
              "description": "Tutto a portata di App!",
              "link_android": "https://play.google.com/store/apps/details?id=it.h3g.areaclienti3&hl=it",
              "link_ios": "https://itunes.apple.com/it/app/area-clienti-3/id436132627?mt=8",
              "title": "My3"
            },
            "windtre": {
              "button": "Scopri",
              "description": "Con l'App WINDTRE potrai verificare i tuoi dati, controllare le tue Fatture, gestire le tue SIM, condividere Giga e tanto altro ancora.",
              "link_android": "https://play.google.com/store/apps/details?id=it.wind.myWind",
              "link_ios": "https://itunes.apple.com/it/app/mywind-app-ufficiale-wind/id492519547?mt=8&amp;ign-mpt=uo%3D4",
              "title": "Un’App unica per la tua rete"
            }
          },
          "fttc": {
            "ull": {
              "general_detail": {
                "text": "<p>Il passaggio della tua linea <b>in tecnologia FTTC</b> impiegherà circa 20 giorni.</p><p>Con il servizio <b>internet in tecnologia FTTC</b> potrai raggiungere una velocità di navigazione fino a 100 Mega o 200 Mega ove previsto.</p>"
              },
              "navigation_faq": {
                "better_wifi": {
                  "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                  "title": "Raggiungi il massimo della velocità"
                },
                "optimal_speed": {
                  "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                  "title": "Ti serve ancora più copertura?"
                },
                "wifi_connection": {
                  "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                  "title": "Ottimizza la connessione Wi-Fi"
                }
              },
              "next_step": {
                "activation": {
                  "description": "Riceverai un SMS con la data di prevista attivazione e l'SMS di conferma attivazione",
                  "title": "attivazione linea"
                },
                "appointment": {
                  "description": "Se non lo hai già ricevuto in negozio, sarai contattato per la consegna del modem sul numero di celullare che hai fornito in uno dei seguenti modi:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                  "title": "prendi l'appuntamento con il corriere"
                },
                "configuration": {
                  "description": "Il giorno dell'attivazione basterà attaccare il Modem alla presa e attendere l'autoinstallazione.<br>Per maggiori info <a class='selfcare-help__link' href='https://www.windtre.it/assistenza-modem-windtre'>guarda il video-tutorial per l'installazione del modem</a> oppure segui le istruzioni presenti nella confezione del modem.",
                  "title": "configurazione modem"
                },
                "confirm": {
                  "description": "Per verificare in ogni momento lo stato dell'ordine vai sull'App WINDTRE. Se non l'hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                  "title": "segui il tuo ordine"
                },
                "fp": {
                  "activation": {
                    "description": "Riceverai un SMS con la data di prevista attivazione e l'SMS di conferma attivazione.",
                    "title": "attivazione linea"
                  },
                  "configuration": {
                    "description": "Il giorno dell'attivazione basterà attaccare il Modem alla presa e attendere l'autoinstallazione.<br>Per maggiori info <a class='selfcare-help__link' href='https://www.windtre.it/assistenza-modem-windtre'>guarda il video-tutorial per l'installazione del modem</a> oppure segui le istruzioni presenti nella confezione del modem.",
                    "title": "configurazione modem"
                  },
                  "confirm": {
                    "description": "Per verificare in ogni momento lo stato dell'ordine vai sull'App WINDTRE. Se non l'hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                    "title": "conferma attivazione"
                  }
                },
                "title": "Prossimi Passi"
              },
              "remember": {
                "arrow_title": "ricorda",
                "remember_modem": "<b>Il giorno in cui il servizio sarà attivato, le attività tecniche necessarie potrebbero causare una temporanea sospensione del servizio<br/><br/>Per chiamare utilizzando il servizio in tecnologia FTTC, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>"
              },
              "title": "Ecco i prossimi passaggi per l’attivazione della tua offerta Internet",
              "useful_info": {
                "title": "Sfrutta al massimo la tua connessione"
              }
            },
            "ull_la": {
              "general_detail": {
                "text": "<p>L'attivazione dell'Offerta sul tuo numero di telefono impiegherà circa 20 giorni.</p><p>In tecnologia <b>FTTC</b> potrai raggiungere una velocità massima di navigazione fino a 100 Mega o 200 Mega ove disponibile.<br/>In tecnologia <b>ADSL</b> potrai navigare fino a 20 Mega. </p>"
              },
              "navigation_faq": {
                "better_wifi": {
                  "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                  "title": "Raggiungi il massimo della velocità"
                },
                "optimal_speed": {
                  "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                  "title": "Ti serve ancora più copertura?"
                },
                "wifi_connection": {
                  "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                  "title": "Ottimizza la connessione Wi-Fi"
                }
              },
              "next_step": {
                "activation": {
                  "description": "Riceverai un SMS con la data di prevista attivazione e l'SMS di conferma attivazione",
                  "title": "attivazione linea"
                },
                "appointment": {
                  "description": "Se non lo hai già ricevuto in negozio, sarai contattato per la consegna del modem sul numero di celullare che hai fornito in uno dei seguenti modi:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                  "title": "prendi l'appuntamento con il corriere"
                },
                "configuration": {
                  "description": "Il giorno dell'attivazione basterà attaccare il Modem alla presa e attendere l'autoinstallazione.<br>Per maggiori info <a class='selfcare-help__link' href='https://www.windtre.it/assistenza-modem-windtre'>guarda il video-tutorial per l'installazione del modem</a> oppure segui le istruzioni presenti nella confezione del modem.",
                  "title": "configurazione modem"
                },
                "confirm": {
                  "description": "Per verificare in ogni momento lo stato dell'ordine vai sull'App WINDTRE. Se non l’hai ancora fatto, scarica l'App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                  "title": "segui il tuo ordine"
                },
                "title": "Prossimi Passi"
              },
              "remember": {
                "arrow_title": "ricorda",
                "permission_description": "<b>Il giorno in cui il servizio sarà attivato, le attività tecniche necessarie potrebbero causare una temporanea sospensione del servizio<br/><br/>Per chiamare utilizzando il servizio in tecnologia FTTC, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>"
              },
              "title": "Ecco i prossimi passaggi per l’attivazione della tua offerta Internet",
              "useful_info": {
                "title": "Sfrutta al massimo la tua connessione"
              }
            },
            "ull_lna": {
              "general_detail": {
                "text": "<p>L'attivazione dell'Offerta Internet su una nuova linea telefonica impiegherà circa 20 giorni.</p><p><b>In tecnologia FTTC</b> potrai raggiungere una velocità massima di navigazione fino a 100 Mega o 200 Mega ove disponibile.<br/></p><p><b>In tecnologia ADSL</b> potrai navigare fino a 20 Mega.</p>"
              },
              "navigation_faq": {
                "better_wifi": {
                  "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                  "title": "Raggiungi il massimo della velocità"
                },
                "optimal_speed": {
                  "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                  "title": "Ti serve ancora più copertura?"
                },
                "wifi_connection": {
                  "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                  "title": "Ottimizza la connessione Wi-Fi"
                }
              },
              "next_step": {
                "appointment": {
                  "description": "Se non lo hai già ricevuto in negozio, sarai contattato per la consegna del modem sul numero di celullare che hai fornito in uno dei seguenti modi:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                  "title": "prendi l'appuntamento con il corriere"
                },
                "configuration": {
                  "description": "Il giorno dell'attivazione basterà attaccare il Modem alla presa e attendere l'autoinstallazione.<br>Per maggiori info <a class='selfcare-help__link' href='https://www.windtre.it/assistenza-modem-windtre'>guarda il video-tutorial per l'installazione del modem</a> oppure segui le istruzioni presenti nella confezione del modem.",
                  "title": "inizia a navigare"
                },
                "confirm": {
                  "description": "Per verificare in ogni momento lo stato dell'ordine vai sull'App WINDTRE. Se non l'hai ancora fatto, scarica l'App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                  "title": "segui il tuo ordine"
                },
                "installation": {
                  "description": "Il giorno dell'appuntamento, il tecnico provvederà ad installare la presa telefonica principale<br/><br/><b>la durata dell'intervento è di circa 1 ora</b>",
                  "title": "installazione"
                },
                "technitian": {
                  "description": "Sarai contattato nei seguenti modi per fissare l'appuntamento con il tecnico per l'installazione della tua nuova linea e della presa se già non presente:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                  "title": "prendi l'appuntamento con il tecnico"
                },
                "title": "Prossimi Passi"
              },
              "remember": {
                "arrow_title": "ricorda",
                "remember_modem": "<b>Per chiamare utilizzando il servizio in tecnologia FTTC, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>"
              },
              "title": "Ecco i prossimi passi per l'attivazione della tua Fibra",
              "useful_info": {
                "title": "Sfrutta al massimo la tua connessione"
              }
            },
            "ull_lna_np": {
              "general_detail": {
                "text": "<p>L'Offerta Internet sarà attivata in circa 20 giorni, inizialmente su di un numero provvisorio che ti verrà comunicato con un SMS</p><p>Successivamente si procederà al passaggio del tuo numero che impiegherà circa 15 giorni e varia in base all'operatore telefonico di provenienza</p><br/>In attesa che avvenga il passaggio del tuo numero, oltre a navigare, potrai chiamare:<br/><ul><li>da subito con il numero provvisorio ( in tecnologia FTTC ricordati di collegare il telefono al modem )</li><li>oppure continuare ad usare il tuo numero con il precedente operatore lasciando il telefono collegato alla presa telefonica.</li></ul></p><p>Ti avviseremo con un SMS quando il tuo numero sostituirà quello provvisorio. Da quel momento potrai utilizzare il tuo numero esclusivamente dal telefono collegato al modem.</p><p><b>In tecnologia FTTC</b> potrai raggiungere una velocità massima di navigazione fino a 100 Mega o 200 Mega ove disponibile.</p><p><b>In tecnologia ADSL</b> potrai navigare fino a 20 Mega</p>"
              },
              "navigation_faq": {
                "better_wifi": {
                  "description": "Se hai bisogno del massimo delle prestazioni dalla tua Fibra (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione",
                  "title": "Raggiungi il massimo della velocità"
                },
                "optimal_speed": {
                  "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                  "title": "Ti serve ancora più copertura?"
                },
                "wifi_connection": {
                  "description": "Assicurati che il modem non sia coperto o nascosto e posizionalo possibilmente a circa 1 metro da terra. Ricorda che la connessione può risultare più debole in presenza di pareti molto spesse, componenti di arredo metallici o dispositivi a radio frequenze come baby monitor, forni a microonde ed eventuali altri modem.",
                  "title": "Ottimizza la connessione Wi-Fi  "
                }
              },
              "next_step": {
                "appointment": {
                  "description": "Se non lo hai già ricevuto in negozio, sarai contattato per la consegna del modem sul numero di celullare che hai fornito in uno dei seguenti modi:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                  "title": "prendi l'appuntamento con il corriere"
                },
                "configuration": {
                  "description": "Il giorno dell'attivazione basterà attaccare il Modem alla presa e attendere l'autoinstallazione.<br>Per maggiori info <a class='selfcare-help__link' href='https://www.windtre.it/assistenza-modem-windtre'>guarda il video-tutorial per l'installazione del modem</a> oppure segui le istruzioni presenti nella confezione del modem.",
                  "title": "inizia a navigare"
                },
                "confirm": {
                  "description": "Per verificare in ogni momento lo stato dell'ordine vai sull'App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                  "title": "segui il tuo ordine"
                },
                "installation": {
                  "description": "Il giorno dell'appuntamento, il tecnico provvederà ad installare la presa telefonica principale<br/><br/><b>la durata dell'intervento è di circa 1 ora</b>",
                  "title": "installazione"
                },
                "technitian": {
                  "description": "Sarai contattato nei seguenti modi per fissare l'appuntamento con il tecnico per l'installazione della tua nuova linea e della presa se già non presente:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul><br>Riceverai un SMS con la data di previsto passaggio del numero (che impiegherà circa 20 giorni) e successivamente un SMS di conferma.",
                  "title": "prendi l'appuntamento con il tecnico"
                },
                "title": "Prossimi Passi"
              },
              "remember": {
                "arrow_title": "ricorda",
                "remember_modem": "<b>Per chiamare utilizzando il servizio in tecnologia FTTC, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>"
              },
              "title": "Ecco i prossimi passi per l'attivazione della tua Fibra",
              "useful_info": {
                "title": "Sfrutta al massimo la tua connessione"
              }
            }
          },
          "ftth": {
            "activation_info": {
              "existing_line": {
                "description": "Se hai richiesto la Fibra sul tuo numero di telefono, la Fibra sarà attivata in circa 20 giorni, inizialmente su di un numero provvisorio che potrai utilizzare da subito e che ti verrà comunicato con un SMS.<br/> Successivamente si procederà al passaggio del tuo numero che impiegherà circa 15 giorni e varia in base all'operatore telefonico di provenienza.<br/>In attesa che avvenga il passaggio del tuo numero, oltre a navigare, potrai chiamare:<ul><li>da subito con il numero provvisorio collegando il telefono al modem;</li><li>oppure continuare ad usare il tuo numero con il precedente operatore lasciando il telefono collegato alla presa telefonica</li></ul> Ti avviseremo con un SMS quando il tuo numero sostituirà quello provvisorio. Da quel momento potrai utilizzare il tuo numero esclusivamente dal telefono collegato al modem.",
                "title": "Attivazione su linea esistente"
              },
              "new_line": {
                "description": "Se hai richiesto la Fibra su una nuova linea telefonica, l'attivazione impiegherà circa 20 giorni ed il nuovo numero ti verrà comunicato con un SMS",
                "title": "Attivazione su nuova linea"
              }
            },
            "additional_service": {
              "description": "Approfitta dei nostri servizi aggiuntivi durante l’intervento del tecnico per portare la Fibra dove vuoi tu.",
              "fiber": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di installare la presa Fibra in un punto della casa o dell’ufficio diverso dall’ingresso o comunque oltre i 5 metri lineari (entro i quali il servizio è incluso e gratuito). Questo prolungamento della Fibra può esserti utile se preferisci avere il modem lontano dall’ingresso (ad esempio in camera da letto o nello studio). Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 65€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo riposizionamento presa telefonica”.",
                "title": "Riposizionamento della presa"
              },
              "phone": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di abilitare tutte le prese esistenti di casa o dell’ufficio al funzionamento dei tuoi telefoni (il servizio è incluso e gratuito per un solo telefono, collegato direttamente al modem). L’abilitazione di tutte le prese è la scelta ideale per utilizzare più telefoni nelle varie stanze. Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 35€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo abilitazione altre prese telefoniche”.",
                "title": "Abilitazione voce ad altre prese telefoniche"
              },
              "title": "Personalizza la tua Fibra"
            },
            "navigation_faq": {
              "better_wifi": {
                "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                "title": "Raggiungi il massimo della velocità"
              },
              "optimal_speed": {
                "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                "title": "Ti serve ancora più copertura?"
              },
              "wifi_connection": {
                "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                "title": "Ottimizza la connessione Wi-Fi"
              }
            },
            "next_step": {
              "configuration": {
                "description": "Riceverai un SMS con il numero di telefono e la conferma dell’attivazione della linea.<br>Se hai richiesto la portabilità del tuo numero, ricorda che andrà a buon fine entro 7 giorni. Nel frattempo potrai comunque chiamare con il numero provvisorio.",
                "title": "inizia a navigare"
              },
              "delivery": {
                "description": "Se non lo hai già ricevuto in negozio, sarai contattato per la consegna del modem sul numero di cellulare che hai fornito in uno dei seguenti modi:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il corriere"
              },
              "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
              "installation": {
                "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>Collegherà la Fibra dal pianerottolo all'interno di casa o dell'ufficio.</li><li>installerà la presa Fibra entro 5 metri dall’ingresso, possibilmente vicino a una presa elettrica</li><li>configurerà il modem verificandone il corretto funzionamento.</li></ul>",
                "title": "ricevi il tecnico per l'installazione"
              },
              "order": {
                "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                "title": "Segui il tuo ordine"
              },
              "technitian": {
                "description": "Sarai contattato in uno seguenti modi per fissare data e fascia oraria per la consegna del modem e l'installazione della Fibra:<ul><li>SMS, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il tecnico"
              },
              "title": "Prossimi passi"
            },
            "remember": {
              "arrow_title": "ricorda",
              "permission_description": "<b>Verifica se il tuo amministratore è già stato contattato per il rilascio dei permessi necessari ai lavori per l'attivazione del servizio presso il tuo stabile.<br/><br/>Per chiamare, ricorda di tenere il modem sempre acceso e collegato al telefono.</b>",
              "remember_modem": "<b>Per chiamare utilizzando il servizio in tecnologia FTTH, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>",
              "voice_service": "<b>Se non richiedi questi servizi aggiuntivi ''premium'' il servizio voce potrà essere utilizzato solo con la presa telefonica principale.</b>"
            },
            "title": "Ecco i prossimi passi per l'attivazione della tua Fibra",
            "useful_info": {
              "title": "Sfrutta al massimo la tua connessione"
            }
          },
          "ftth-fp": {
            "activation_info": {
              "existing_line": {
                "description": "Se hai richiesto la Fibra sul tuo numero di telefono, la Fibra sarà attivata in circa 20 giorni, inizialmente su di un numero provvisorio che potrai utilizzare da subito e che ti verrà comunicato con un SMS.<br/> Successivamente si procederà al passaggio del tuo numero che impiegherà circa 15 giorni e varia in base all'operatore telefonico di provenienza.<br/>In attesa che avvenga il passaggio del tuo numero, oltre a navigare, potrai chiamare:<ul><li>da subito con il numero provvisorio collegando il telefono al modem;</li><li>oppure continuare ad usare il tuo numero con il precedente operatore lasciando il telefono collegato alla presa telefonica</li></ul> Ti avviseremo con un SMS quando il tuo numero sostituirà quello provvisorio. Da quel momento potrai utilizzare il tuo numero esclusivamente dal telefono collegato al modem.",
                "title": "Attivazione su linea esistente"
              },
              "new_line": {
                "description": "Se hai richiesto la Fibra su una nuova linea telefonica, l'attivazione impiegherà circa 20 giorni ed il nuovo numero ti verrà comunicato con un SMS",
                "title": "Attivazione su nuova linea"
              }
            },
            "additional_service": {
              "description": "Approfitta dei nostri servizi aggiuntivi durante l’intervento del tecnico per portare la Fibra dove vuoi tu.",
              "fiber": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di installare la presa Fibra in un punto della casa o dell’ufficio diverso dall’ingresso o comunque oltre i 5 metri lineari (entro i quali il servizio è incluso e gratuito). Questo prolungamento della Fibra può esserti utile se preferisci avere il modem lontano dall’ingresso (ad esempio in camera da letto o nello studio). Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 65€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo riposizionamento presa telefonica”.",
                "title": "Riposizionamento della presa"
              },
              "phone": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di abilitare tutte le prese esistenti di casa o dell’ufficio al funzionamento dei tuoi telefoni (il servizio è incluso e gratuito per un solo telefono, collegato direttamente al modem). L’abilitazione di tutte le prese è la scelta ideale per utilizzare più telefoni nelle varie stanze. Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 35€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo abilitazione altre prese telefoniche”.",
                "title": "Abilitazione voce ad altre prese telefoniche"
              },
              "title": "Personalizza la tua Fibra"
            },
            "navigation_faq": {
              "better_wifi": {
                "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                "title": "Raggiungi il massimo della velocità"
              },
              "optimal_speed": {
                "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                "title": "Ti serve ancora più copertura?"
              },
              "wifi_connection": {
                "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                "title": "Ottimizza la connessione Wi-Fi"
              }
            },
            "next_step": {
              "appointment": {
                "description": "Se non lo hai già ricevuto in negozio, sarai contattato per la consegna del modem sul numero di celullare che hai fornito in uno dei seguenti modi:<ul><li>SMS contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il corriere"
              },
              "configuration": {
                "description": "Riceverai un SMS con il numero di telefono e la conferma dell’attivazione della linea.<br>Se hai richiesto la portabilità del tuo numero, ricorda che andrà a buon fine entro 7 giorni. Nel frattempo potrai comunque chiamare con il numero provvisorio.",
                "title": "inizia a navigare"
              },
              "installation": {
                "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>collegherà la Fibra dal pianerottolo all'interno di casa o dell'ufficio.</li><li>installerà la presa Fibra entro 5 metri dall’ingresso, possibilmente vicino a una presa elettrica</li><li>configurerà il modem verificandone il corretto funzionamento.</li></ul>",
                "title": "ricevi il tecnico per l'installazione"
              },
              "order": {
                "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                "title": "Segui il tuo ordine"
              },
              "technitian": {
                "description": "Sarai contattato in uno seguenti modi per fissare data e fascia oraria per la consegna del modem e l’installazione della Fibra:<ul><li>SMS, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il tecnico"
              },
              "title": "Prossimi Passi"
            },
            "remember": {
              "arrow_title": "ricorda",
              "permission_description": "<b>Verifica se il tuo amministratore è già stato contattato per il rilascio dei permessi necessari ai lavori per l'attivazione del servizio presso il tuo stabile.<br/><br/>Per chiamare, ricorda di tenere il modem sempre acceso e collegato al telefono.</b>",
              "remember_modem": "<b>Per chiamare utilizzando il servizio in tecnologia FTTH, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>",
              "voice_service": "<b>Se non richiedi questi servizi aggiuntivi ''premium'' il servizio voce potrà essere utilizzato solo con la presa telefonica principale.</b>"
            },
            "title": "Ecco i prossimi passi per l’attivazione della tua Fibra",
            "useful_info": {
              "title": "Sfrutta al massimo la tua connessione"
            }
          },
          "ftth_aree": {
            "activation_info": {
              "existing_line": {
                "description": "Se hai richiesto la Fibra sul tuo numero di telefono, la Fibra sarà attivata in circa 30 giorni, inizialmente su di un numero provvisorio che potrai utilizzare da subito e che ti verrà comunicato con un SMS.<br/> Successivamente si procederà al passaggio del tuo numero che impiegherà circa 15 giorni e varia in base all'operatore telefonico di provenienza.<br/>In attesa che avvenga il passaggio del tuo numero, oltre a navigare, potrai chiamare:<ul><li>da subito con il numero provvisorio collegando il telefono al modem;</li><li>oppure continuare ad usare il tuo numero con il precedente operatore lasciando il telefono collegato alla presa telefonica</li></ul> Ti avviseremo con un SMS quando il tuo numero sostituirà quello provvisorio. Da quel momento potrai utilizzare il tuo numero esclusivamente dal telefono collegato al modem.",
                "title": "Attivazione su linea esistente"
              },
              "new_line": {
                "description": "Se hai richiesto la Fibra su una nuova linea telefonica, l'attivazione impiegherà circa 30 giorni ed il nuovo numero ti verrà comunicato con un SMS",
                "title": "Attivazione su nuova linea"
              }
            },
            "additional_service": {
              "description": "Approfitta dei nostri servizi aggiuntivi durante l’intervento del tecnico per portare la Fibra dove vuoi tu.",
              "fiber": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di installare la presa Fibra in un punto della casa o dell’ufficio diverso dall’ingresso o comunque oltre i 5 metri lineari (entro i quali il servizio è incluso e gratuito). Questo prolungamento della Fibra può esserti utile se preferisci avere il modem lontano dall’ingresso (ad esempio in camera da letto o nello studio). Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 65€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo riposizionamento presa telefonica”.",
                "title": "Riposizionamento della presa"
              },
              "phone": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di abilitare tutte le prese esistenti di casa o dell’ufficio al funzionamento dei tuoi telefoni (il servizio è incluso e gratuito per un solo telefono, collegato direttamente al modem). L’abilitazione di tutte le prese è la scelta ideale per utilizzare più telefoni nelle varie stanze. Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 35€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo abilitazione altre prese telefoniche”.",
                "title": "Abilitazione voce ad altre prese telefoniche"
              },
              "title": "Personalizza la tua Fibra"
            },
            "banner": {
              "link": "Per maggiori informazioni consulta la <a class='selfcare-help__link' href='https://d1qsjop7tycsfb.cloudfront.net/fileadmin/mainbrand/20200622/fisso/Scheda_prodotto_ZYXEL_WAP6807.pdf'>SCHEDA TECNICA</a> del Wi-Fi Extender",
              "title": "Per te c'è la possibilità di avere una SIM dati per navigare in internet ad un prezzo speciale!"
            },
            "navigation_faq": {
              "better_wifi": {
                "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                "title": "Raggiungi il massimo della velocità"
              },
              "optimal_speed": {
                "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                "title": "Ti serve ancora più copertura?"
              },
              "wifi_connection": {
                "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                "title": "Ottimizza la connessione Wi-Fi"
              }
            },
            "next_step": {
              "configuration": {
                "description": "Riceverai un SMS con il numero di telefono e la conferma dell’attivazione della linea.<br>Se hai richiesto la portabilità del tuo numero, ricorda che andrà a buon fine entro 7 giorni. Nel frattempo potrai comunque chiamare con il numero provvisorio.",
                "title": "INIZIA A NAVIGARE"
              },
              "installation": {
                "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>collegherà la Fibra dal pianerottolo all'interno di casa o dell'ufficio.<li>installerà la presa Fibra entro 5 metri dall’ingresso, possibilmente vicino a una presa elettrica</li><li>configurerà il modem verificandone il corretto funzionamento.</li></ul>",
                "title": "RICEVI IL TECNICO PER L'INSTALLAZIONE"
              },
              "order": {
                "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                "title": "SEGUI IL TUO ORDINE"
              },
              "technitian": {
                "description": "Sarai contattato in uno seguenti modi per fissare data e fascia oraria per la consegna del modem e l’installazione della Fibra:<ul><li>SMS, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "PRENDI L'APPUNTAMENTO CON IL TECNICO"
              },
              "title": "Prossimi Passi"
            },
            "remember": {
              "arrow_title": "ricorda",
              "permission_description": "<b>Verifica se il tuo amministratore è già stato contattato per il rilascio dei permessi necessari ai lavori per l'attivazione del servizio presso il tuo stabile.<br/><br/>Per chiamare, ricorda di tenere il modem sempre acceso e collegato al telefono.</b>",
              "remember_modem": "<b>Per chiamare utilizzando il servizio in tecnologia FTTH, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>",
              "voice_service": "<b>Se non richiedi questi servizi aggiuntivi ''premium'' il servizio voce potrà essere utilizzato solo con la presa telefonica principale.</b>"
            },
            "title": "Ecco i prossimi passaggi per l'attivazione della tua Fibra",
            "useful_info": {
              "title": "Sfrutta al massimo la tua connessione"
            }
          },
          "ftth_ftth": {
            "activation_info": {
              "existing_line": {
                "description": "Se hai richiesto la Fibra sul tuo numero di telefono, la Fibra sarà attivata in circa 20 giorni, inizialmente su di un numero provvisorio che potrai utilizzare da subito e che ti verrà comunicato con un SMS.<br/> Successivamente si procederà al passaggio del tuo numero che impiegherà circa 15 giorni e varia in base all'operatore telefonico di provenienza.<br/>In attesa che avvenga il passaggio del tuo numero, oltre a navigare, potrai chiamare:<ul><li>da subito con il numero provvisorio collegando il telefono al modem;</li><li>oppure continuare ad usare il tuo numero con il precedente operatore lasciando il telefono collegato alla presa telefonica</li></ul> Ti avviseremo con un SMS quando il tuo numero sostituirà quello provvisorio. Da quel momento potrai utilizzare il tuo numero esclusivamente dal telefono collegato al modem.",
                "title": "Attivazione su linea esistente"
              },
              "new_line": {
                "description": "Se hai richiesto la Fibra su una nuova linea telefonica, l'attivazione impiegherà circa 20 giorni ed il nuovo numero ti verrà comunicato con un SMS",
                "title": "Attivazione su nuova linea"
              }
            },
            "additional_service": {
              "description": "Approfitta dei nostri servizi aggiuntivi durante l’intervento del tecnico per portare la Fibra dove vuoi tu.",
              "fiber": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di installare la presa Fibra in un punto della casa o dell’ufficio diverso dall’ingresso o comunque oltre i 5 metri lineari (entro i quali il servizio è incluso e gratuito). Questo prolungamento della Fibra può esserti utile se preferisci avere il modem lontano dall’ingresso (ad esempio in camera da letto o nello studio). Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 65€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo riposizionamento presa telefonica”.",
                "title": "Riposizionamento della presa"
              },
              "phone": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di abilitare tutte le prese esistenti di casa o dell’ufficio al funzionamento dei tuoi telefoni (il servizio è incluso e gratuito per un solo telefono, collegato direttamente al modem). L’abilitazione di tutte le prese è la scelta ideale per utilizzare più telefoni nelle varie stanze. Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 35€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo abilitazione altre prese telefoniche”.",
                "title": "Abilitazione voce ad altre prese telefoniche"
              },
              "title": "Personalizza la tua Fibra"
            },
            "navigation_faq": {
              "better_wifi": {
                "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                "title": "Raggiungi il massimo della velocità"
              },
              "optimal_speed": {
                "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                "title": "Ti serve ancora più copertura?"
              },
              "wifi_connection": {
                "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                "title": "Ottimizza la connessione Wi-Fi"
              }
            },
            "next_step": {
              "configuration": {
                "description": "Riceverai un SMS con il numero di telefono e la conferma dell'attivazione della linea.",
                "title": "inizia a navigare"
              },
              "delivery": {
                "description": "Se non hai già ricevuto il modem in negozio, sarai contattato per la consegna in uno dei seguenti modi sul numero di cellulare che hai fornito:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il corriere"
              },
              "installation": {
                "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>collegherà la Fibra dal pianerottolo all'interno di casa o dell'ufficio.</li><li>configurerà il modem verificandone il corretto funzionamento.</li></ul>",
                "title": "ricevi il tecnico per l'installazione"
              },
              "order": {
                "description": "Per verificare in ogni momento lo stato dell'ordine vai sull'App WINDTRE. Se non l'hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                "title": "Segui il tuo ordine"
              },
              "technitian": {
                "description": "Sarai contattato in uno seguenti modi per fissare data e fascia oraria per la consegna del modem e l’installazione della Fibra:<ul><li>SMS, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il tecnico"
              },
              "title": "Prossimi Passi"
            },
            "remember": {
              "arrow_title": "ricorda",
              "permission_description": "<b>Verifica se il tuo amministratore è già stato contattato per il rilascio dei permessi necessari ai lavori per l'attivazione del servizio presso il tuo stabile.<br/><br/>Per chiamare, ricorda di tenere il modem sempre acceso e collegato al telefono.</b>",
              "remember_modem": "<b>Per chiamare utilizzando il servizio in tecnologia FTTH, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>",
              "voice_service": "<b>Se non richiedi questi servizi aggiuntivi ''premium'' il servizio voce potrà essere utilizzato solo con la presa telefonica principale.</b>"
            },
            "title": "Ecco i prossimi passaggi per l'attivazione della tua Fibra",
            "useful_info": {
              "title": "Sfrutta al massimo la tua connessione"
            }
          },
          "ftth_onestep": {
            "activation_info": {
              "existing_line": {
                "description": "Se hai richiesto la Fibra sul tuo numero di telefono, la Fibra sarà attivata in circa 20 giorni, inizialmente su di un numero provvisorio che potrai utilizzare da subito e che ti verrà comunicato con un SMS.<br/> Successivamente si procederà al passaggio del tuo numero che impiegherà circa 15 giorni e varia in base all'operatore telefonico di provenienza.<br/>In attesa che avvenga il passaggio del tuo numero, oltre a navigare, potrai chiamare:<ul><li>da subito con il numero provvisorio collegando il telefono al modem;</li><li>oppure continuare ad usare il tuo numero con il precedente operatore lasciando il telefono collegato alla presa telefonica</li></ul> Ti avviseremo con un SMS quando il tuo numero sostituirà quello provvisorio. Da quel momento potrai utilizzare il tuo numero esclusivamente dal telefono collegato al modem.",
                "title": "Attivazione su linea esistente"
              },
              "new_line": {
                "description": "Se hai richiesto la Fibra su una nuova linea telefonica, l'attivazione impiegherà circa 20 giorni ed il nuovo numero ti verrà comunicato con un SMS",
                "title": "Attivazione su nuova linea"
              }
            },
            "additional_service": {
              "description": "Approfitta dei nostri servizi aggiuntivi durante l’intervento del tecnico per portare la Fibra dove vuoi tu.",
              "fiber": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di installare la presa Fibra in un punto della casa o dell’ufficio diverso dall’ingresso o comunque oltre i 5 metri lineari (entro i quali il servizio è incluso e gratuito). Questo prolungamento della Fibra può esserti utile se preferisci avere il modem lontano dall’ingresso (ad esempio in camera da letto o nello studio). Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 65€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo riposizionamento presa telefonica”.",
                "title": "Riposizionamento della presa"
              },
              "phone": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di abilitare tutte le prese esistenti di casa o dell’ufficio al funzionamento dei tuoi telefoni (il servizio è incluso e gratuito per un solo telefono, collegato direttamente al modem). L’abilitazione di tutte le prese è la scelta ideale per utilizzare più telefoni nelle varie stanze. Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 35€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo abilitazione altre prese telefoniche”.",
                "title": "Abilitazione voce ad altre prese telefoniche"
              },
              "title": "Personalizza la tua Fibra"
            },
            "navigation_faq": {
              "better_wifi": {
                "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                "title": "Raggiungi il massimo della velocità"
              },
              "optimal_speed": {
                "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                "title": "Ti serve ancora più copertura?"
              },
              "wifi_connection": {
                "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                "title": "Ottimizza la connessione Wi-Fi"
              }
            },
            "next_step": {
              "configuration": {
                "description": "Riceverai un SMS con il numero di telefono e la conferma dell’attivazione della linea.<br>Se hai richiesto la portabilità del tuo numero, ricorda che andrà a buon fine entro 7 giorni. Nel frattempo potrai comunque chiamare con il numero provvisorio.",
                "title": "inizia a navigare"
              },
              "installation": {
                "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>collegherà la Fibra dal pianerottolo all'interno di casa o dell'ufficio.</li><li>installerà la presa Fibra entro 5 metri dall’ingresso, possibilmente vicino a una presa elettrica</li><li>configurerà il modem verificandone il corretto funzionamento.</li></ul>",
                "title": "ricevi il tecnico per l'installazione"
              },
              "order": {
                "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                "title": "Segui il tuo ordine"
              },
              "technitian": {
                "description": "Sarai contattato in uno seguenti modi per fissare data e fascia oraria per la consegna del modem e l’installazione della Fibra:<ul><li>SMS, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il tecnico"
              },
              "title": "Prossimi Passi"
            },
            "remember": {
              "arrow_title": "ricorda",
              "permission_description": "<b>Verifica se il tuo amministratore è già stato contattato per il rilascio dei permessi necessari ai lavori per l'attivazione del servizio presso il tuo stabile.<br/><br/>Per chiamare, ricorda di tenere il modem sempre acceso e collegato al telefono.</b>",
              "remember_modem": "<b>Per chiamare utilizzando il servizio in tecnologia FTTH, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>",
              "voice_service": "<b>Se non richiedi questi servizi aggiuntivi ''premium'' il servizio voce potrà essere utilizzato solo con la presa telefonica principale.</b>"
            },
            "title": "Ecco i prossimi passi per l’attivazione della tua Fibra",
            "useful_info": {
              "title": "Sfrutta al massimo la tua connessione"
            }
          },
          "ftth_ull_iso": {
            "fp": {
              "next_step": {
                "configuration": {
                  "description": "Riceverai un SMS con il numero di telefono e la conferma dell’attivazione della linea.<br>Se hai richiesto la portabilità del tuo numero, ricorda che andrà a buon fine entro 7 giorni. Nel frattempo potrai comunque chiamare con il numero provvisorio.",
                  "title": "inizia a navigare"
                },
                "delivery": {
                  "description": "Sarai contattato in uno dei seguenti modi per fissare data e fascia oraria per la consegna dell'adattore Fibra:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                  "title": "prendi l'appuntamento con il corriere"
                },
                "installation": {
                  "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>collegherà la Fibra dal pianerottolo all'interno di casa o dell'ufficio.</li><li>installerà la presa Fibra entro 5 metri dall’ingresso, possibilmente vicino a una presa elettrica</li><li>configurerà il modem verificandone il corretto funzionamento.</li></ul>",
                  "title": "ricevi il tecnico per l'installazione"
                },
                "order": {
                  "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                  "title": "Segui il tuo ordine"
                },
                "technitian": {
                  "description": "Sarai contattato in uno seguenti modi per fissare data e fascia oraria per l'installazione della Fibra:<ul><li>SMS, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                  "title": "prendi l'appuntamento con il tecnico"
                }
              }
            },
            "general_detail": {
              "text": "L'attivazione della Fibra sul tuo numero di telefono impiegherà circa 20 giorni."
            },
            "navigation_faq": {
              "better_wifi": {
                "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                "title": "Raggiungi il massimo della velocità"
              },
              "optimal_speed": {
                "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                "title": "Ti serve ancora più copertura?"
              },
              "wifi_connection": {
                "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                "title": "Ottimizza la connessione Wi-Fi"
              }
            },
            "next_step": {
              "configuration": {
                "description": "Riceverai un SMS con il numero di telefono e la conferma dell’attivazione della linea.<br>Se hai richiesto la portabilità del tuo numero, ricorda che andrà a buon fine entro 7 giorni. Nel frattempo potrai comunque chiamare con il numero provvisorio.",
                "title": "inizia a navigare"
              },
              "delivery": {
                "description": "Se non lo hai già ricevuto in negozio, sarai contattato per la consegna del modem sul numero di cellulare che hai fornito in uno dei seguenti modi:<ul><li>SMS del corriere, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il corriere"
              },
              "installation": {
                "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>collegherà la Fibra dal pianerottolo all'interno di casa o dell'ufficio.</li><li>installerà la presa Fibra entro 5 metri dall’ingresso, possibilmente vicino a una presa elettrica</li><li>configurerà il modem verificandone il corretto funzionamento.</li></ul>",
                "title": "ricevi il tecnico per l'installazione"
              },
              "order": {
                "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                "title": "Segui il tuo ordine"
              },
              "technitian": {
                "description": "Sarai contattato in uno seguenti modi per fissare data e fascia oraria per la consegna del modem e l’installazione della Fibra:<ul><li>SMS, contenente un link per prendere l’appuntamento</li><li>CHIAMATA, per fissare l’appuntamento con un nostro operatore.</li></ul>",
                "title": "prendi l'appuntamento con il tecnico"
              },
              "title": "Prossimi Passi"
            },
            "onestep": {
              "general_detail": {
                "text": "L'attivazione della Fibra sul tuo numero di telefono impiegherà circa 20 giorni."
              },
              "navigation_faq": {
                "better_wifi": {
                  "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                  "title": "Raggiungi il massimo della velocità"
                },
                "optimal_speed": {
                  "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                  "title": "Ti serve ancora più copertura?"
                },
                "wifi_connection": {
                  "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                  "title": "Ottimizza la connessione Wi-Fi"
                }
              },
              "next_step": {
                "configuration": {
                  "description": "Riceverai un SMS con il numero di telefono e la conferma dell’attivazione della linea.<br>Se hai richiesto la portabilità del tuo numero, ricorda che andrà a buon fine entro 7 giorni. Nel frattempo potrai comunque chiamare con il numero provvisorio.",
                  "title": "inizia a navigare"
                },
                "installation": {
                  "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>collegherà la Fibra dal pianerottolo all'interno di casa o dell'ufficio.</li><li>installerà la presa Fibra entro 5 metri dall’ingresso, possibilmente vicino a una presa elettrica</li><li>configurerà il modem verificandone il corretto funzionamento.</li></ul>",
                  "title": "installazione"
                },
                "order": {
                  "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                  "title": "Segui il tuo ordine"
                },
                "technitian": {
                  "description": "Sarai contattato per fissare un appuntamento con un tecnico di nostra fiducia per la consegna del modem e per l'installazione della tua Fibra.",
                  "title": "prendi l'appuntamento con il tecnico"
                },
                "title": "Prossimi Passi"
              }
            },
            "remember": {
              "arrow_title": "ricorda",
              "permission_description": "<b>Verifica se il tuo amministratore è già stato contattato per il rilascio dei permessi necessari ai lavori per l'attivazione del servizio presso il tuo stabile.<br/><br/>Per chiamare, ricorda di tenere il modem sempre acceso e collegato al telefono.</b>"
            },
            "title": "Ecco i prossimi passi per l’attivazione della tua Fibra",
            "useful_info": {
              "title": "Sfrutta al massimo la tua connessione"
            }
          },
          "fwa": {
            "activation_info": {
              "existing_line": {
                "description": "Se hai richiesto la Fibra sul tuo numero di telefono, la Fibra sarà attivata in circa 30 giorni, inizialmente su di un numero provvisorio che potrai utilizzare da subito e che ti verrà comunicato con un SMS.<br/> Successivamente si procederà al passaggio del tuo numero che impiegherà circa 15 giorni e varia in base all'operatore telefonico di provenienza.<br/>In attesa che avvenga il passaggio del tuo numero, oltre a navigare, potrai chiamare:<ul><li>da subito con il numero provvisorio collegando il telefono al modem;</li><li>oppure continuare ad usare il tuo numero con il precedente operatore lasciando il telefono collegato alla presa telefonica</li></ul> Ti avviseremo con un SMS quando il tuo numero sostituirà quello provvisorio. Da quel momento potrai utilizzare il tuo numero esclusivamente dal telefono collegato al modem.",
                "title": "Attivazione su linea esistente"
              },
              "new_line": {
                "description": "Se hai richiesto la Fibra su una nuova linea telefonica, l'attivazione impiegherà circa 30 giorni ed il nuovo numero ti verrà comunicato con un SMS",
                "title": "Attivazione su nuova linea"
              }
            },
            "additional_service": {
              "description": "Pagherai questi interventi comodamente in fattura e il giorno dell'appuntamento potrai decidere di richiedere questi servizi e li pagherai comodamente in fattura. L'impianto sarà certificato e garantito dalla nostra completa assistenza.",
              "fiber": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di installare la presa Fibra in un punto della casa o dell’ufficio diverso dall’ingresso o comunque oltre i 5 metri lineari (entro i quali il servizio è incluso e gratuito). Questo prolungamento della Fibra può esserti utile se preferisci avere il modem lontano dall’ingresso (ad esempio in camera da letto o nello studio). Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 65€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo riposizionamento presa telefonica”.",
                "title": "Riposizionamento della presa"
              },
              "phone": {
                "description": "Il giorno dell’attivazione puoi richiedere al tecnico di abilitare tutte le prese esistenti di casa o dell’ufficio al funzionamento dei tuoi telefoni (il servizio è incluso e gratuito per un solo telefono, collegato direttamente al modem). L’abilitazione di tutte le prese è la scelta ideale per utilizzare più telefoni nelle varie stanze. Il servizio è opzionale e prevede un costo aggiuntivo una tantum di 35€, che ti verrà addebitato sul primo conto telefonico alla voce “Costo abilitazione altre prese telefoniche”.",
                "title": "Abilitazione voce ad altre prese telefoniche"
              },
              "title": "Sfrutta al massimo la tua connessione"
            },
            "banner": {
              "link": "Per maggiori informazioni consulta la <a class='selfcare-help__link' href='https://d1qsjop7tycsfb.cloudfront.net/fileadmin/mainbrand/20200622/fisso/Scheda_prodotto_ZYXEL_WAP6807.pdf'>SCHEDA TECNICA</a> del Wi-Fi Extender",
              "title": "Per te c'è la possibilità di avere una SIM dati per navigare in internet ad un prezzo speciale!"
            },
            "navigation_faq": {
              "better_wifi": {
                "description": "Se hai bisogno del massimo delle prestazioni dalla tua connessione (ad esempio per il gaming online o lo streaming video in 4K), collega direttamente al modem il dispositivo che stai utilizzando (PC, Smart TV, decoder, console, ecc.) con il cavo LAN in dotazione.",
                "title": "Raggiungi il massimo della velocità"
              },
              "optimal_speed": {
                "description": "Se vuoi essere certo che il segnale Wi-Fi sia eccellente in ogni ambiente di casa o ufficio, anche su più livelli, puoi contare sul nostro Wi-Fi Extender. Scoprilo <a class='selfcare-help__link' href='https://www.windtre.it/offerte-fibra/extender/ '>qui.</a>!",
                "title": "Ti serve ancora più copertura?"
              },
              "wifi_connection": {
                "description": "Assicurati che il Modem non sia coperto o nascosto e posizionalo possibilmente su un mobile a circa 1 mt da terra. <br/>Diversi fattori possono attenuare il segnale es: muri, arredamenti soprattutto se metallici, dispositivi a radio frequenze come altri modem nelle vicinanze, sensori antifurto, telecamere di sorveglianza, forni a microonde.",
                "title": "Ottimizza la connessione Wi-Fi"
              }
            },
            "next_step": {
              "configuration": {
                "description": "Riceverai un SMS con il numero di telefono e la conferma dell’attivazione della linea.<br>Se hai richiesto la portabilità del tuo numero, ricorda che andrà a buon fine entro 7 giorni. Nel frattempo potrai comunque chiamare con il numero provvisorio.",
                "title": "inizia a navigare"
              },
              "installation": {
                "description": "Il tecnico certificato effettuerà un intervento di circa 2 ore, durante le quali:<ul><li>Installerà l’antenna esterna<li>Collegherà, tramite cavo ethernet, l’antenna con il modem all’interno della casa o dell’ufficio, in modo da ottimizzare le prestazioni della rete</li><li>Configurerà il modem verificandone il corretto funzionamento.</li></ul>L’installazione dell’antenna avverrà in una zona facilmente accessibile, per esempio sul balcone o sulla parete esterna. Se necessario sarà possibile l’installazione anche sul tetto, o sul cappotto termico, previa fattibilità tecnica ed autorizzazione del proprietario dell’immobile.",
                "title": "ricevi il tecnico per l'installazione"
              },
              "order": {
                "description": "Per verificare in ogni momento lo stato dell’ordine vai sull’App WINDTRE. Se non l’hai ancora fatto, scarica l’App <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre/'>qui.</a>",
                "title": "Segui il tuo ordine"
              },
              "technitian": {
                "description": "Sarai chiamato, sul numero che hai dato in fase di sottoscrizione del contratto, per fissare data e fascia oraria per la consegna del modem e l’installazione della linea Internet.",
                "title": "prendi l'appuntamento con il tecnico"
              },
              "title": "Prossimi Passi"
            },
            "remember": {
              "arrow_title": "ricorda",
              "permission_description": "<b>Per chiamare, ricorda di tenere il modem sempre acceso e collegato al telefono.</b>",
              "remember_modem": "<b>Per chiamare utilizzando il servizio in tecnologia FTTH, ricordati di mantenere il modem sempre acceso e collegato al telefono.</b>",
              "voice_service": "<b>Se non richiedi questi servizi aggiuntivi ''premium'' il servizio voce potrà essere utilizzato solo con la presa telefonica principale.</b>"
            },
            "title": "Ecco i prossimi passaggi per l'attivazione della tua offerta Super Internet",
            "useful_info": {
              "title": "Sfrutta al massimo la tua connessione"
            }
          }
        },
        "mobile": {
          "tre": {
            "postpay": {
              "no_mnp": {
                "my3": {
                  "login": "<ul><li>Quando sei sotto rete 3 clicca su “Login automatico” senza digitare le credenziali;</li><li>Quando sei in Wi-Fi, registrati e memorizza la password, così non dovrai più inserirla in seguito.</li></ul>",
                  "login_alternative": "Se preferisci utilizzare un PC/Mac/Tablet puoi accedere alle stesse informazioni accedendo <b>all'Area Clienti</b> dal tuo web browser digitando",
                  "login_help": "Potrai accedere all'app My3 trascorse 24 ore dal messaggio SMS di conferma di abilitazione dell'offerta.",
                  "login_link": "https://areaclienti3.tre.it/133/",
                  "login_title": "Accedere all'app è semplicissimo",
                  "need_help": "E se hai bisogno di aiuto, l'assistente digitale <b>Trelpy</b> sarà a tua disposizione.",
                  "text": "<ul><li>Visualizzare l'<b>offerta attiva e i tuoi costi</b></li><li>Controllare i tuoi <b>consumi</b>, le tue soglie e consultare le <b>fatture</b></li><li>Verificare i dettagli del tuo metodo di pagamento</li><li><b>Ricaricare</b> in un attimo il numero che vuoi</li><li><b>Conoscere</b> le opzioni da aggiungere alla tua offerta</li><li>Verificare PIN e PUK </li><li><b>Associare</b> le tue SIM per gestire tutte le linee mobili da un unico accesso</li></ul>",
                  "title": "Con My3 puoi:"
                },
                "title": "Lorem ipsum dolor sit amet dolor lorem sit amet",
                "under_control": {
                  "description": "<b>My3 è l'app gratuita</b> pensata per gestire i tuoi numeri dal tuo Smartphone ed essere sempre aggiornato sulle ultime novità di 3.",
                  "title": "Tutto sotto controllo"
                }
              },
              "port_in": {
                "description": {
                  "portability": "Ti ricordiamo che il passaggio del tuo numero sulla SIM Tre avverrà alla data presunta indicata nel modulo di richiesta. Tuttavia si specifica che: 'La data di attivazione del Servizio MNP potrà in ogni caso differire da quella indicata ed, in particolare, essere anticipata, subire ritardi o essere rifiutata per le cause descritte nell'Art. 4 del Regolamento di Servizio MNP'.",
                  "sms": "Alla ricezione dell'SMS di conferma di avvenuta migrazione del numero ti ricordiamo che per il corretto funzionamento in rete occorre spegnere e riaccendere il telefono.",
                  "title": "Portabilità",
                  "transfer": "Se hai anche richiesto il trasferimento del credito residuo che avevi con il precedente operatore, ti verrà decurtato il costo di trasferimento applicato dall'operatore cedente. L'operazione avverrà entro 72 ore dall' avvenuta portabilità e riceverai conferma tramite SMS."
                },
                "my3": {
                  "login": "<ul><li>Quando sei sotto rete 3 clicca su “Login automatico” senza digitare le credenziali;</li><li>Quando sei in Wi-Fi, registrati e memorizza la password, così non dovrai più inserirla in seguito.</li></ul>",
                  "login_alternative": "Se preferisci utilizzare un PC/Mac/Tablet puoi accedere alle stesse informazioni accedendo <b>all'Area Clienti</b> dal tuo web browser digitando",
                  "login_help": "Potrai accedere all'app My3 trascorse 24 ore dal messaggio SMS di conferma di abilitazione dell'offerta.",
                  "login_link": "https://areaclienti3.tre.it/133/",
                  "login_title": "Accedere all'app è semplicissimo",
                  "need_help": "E se hai bisogno di aiuto, l'assistente digitale <b>Trelpy</b> sarà a tua disposizione.",
                  "text": "<ul><li>Visualizzare l'<b>offerta attiva e i tuoi costi</b></li><li>Controllare i tuoi <b>consumi</b>, le tue soglie e consultare le <b>fatture</b></li><li>Verificare i dettagli del tuo metodo di pagamento</li><li><b>Ricaricare</b> in un attimo il numero che vuoi</li><li><b>Conoscere</b> le opzioni da aggiungere alla tua offerta</li><li>Verificare PIN e PUK </li><li><b>Associare</b> le tue SIM per gestire tutte le linee mobili da un unico accesso</li></ul>",
                  "title": "Con My3 puoi: "
                },
                "title": "Lorem ipsum dolor sit amet dolor lorem sit amet",
                "under_control": {
                  "description": "<b>My3 è l'app gratuita</b> pensata per gestire i tuoi numeri dal tuo Smartphone ed essere sempre aggiornato sulle ultime novità di 3.",
                  "title": "Tutto sotto controllo"
                }
              }
            },
            "prepay": {
              "tied": {
                "no_mnp": {
                  "my3": {
                    "login": "<ul><li>Quando sei sotto rete 3 clicca su “Login automatico” senza digitare le credenziali;</li><li>Quando sei in Wi-Fi, registrati e memorizza la password, così non dovrai più inserirla in seguito.</li></ul>",
                    "login_alternative": "Se preferisci utilizzare un PC/Mac/Tablet puoi accedere alle stesse informazioni accedendo <b>all'Area Clienti</b> dal tuo web browser digitando",
                    "login_help": "Potrai accedere all'app My3 trascorse 24 ore dal messaggio SMS di conferma di abilitazione dell'offerta.",
                    "login_link": "https://areaclienti3.tre.it/133/",
                    "login_title": "Accedere all'app è semplicissimo",
                    "need_help": "E se hai bisogno di aiuto, l'assistente digitale <b>Trelpy</b> sarà a tua disposizione.",
                    "text": "<ul><li>Visualizzare l'<b>offerta attiva e i tuoi costi</b></li><li>Controllare i tuoi <b>consumi</b>, il tuo credito e le tue soglie</li><li>Verificare i dettagli del tuo metodo di pagamento</li><li><b>Ricaricare</b> in un attimo il numero che vuoi</li><li><b>Conoscere</b> le opzioni da aggiungere alla tua offerta</li><li>Verificare PIN e PUK </li><li><b>Associare</b> le tue SIM per gestire tutte le linee mobili da un unico accesso</li></ul>",
                    "title": "Con My3 puoi:"
                  },
                  "title": "Lorem ipsum dolor sit amet dolor lorem sit amet",
                  "under_control": {
                    "description": "<b>My3 è l'app gratuita</b> pensata per gestire i tuoi numeri dal tuo Smartphone ed essere sempre aggiornato sulle ultime novità di 3.",
                    "title": "Tutto sotto controllo"
                  }
                },
                "port_in": {
                  "description": {
                    "credit": "Il credito trasferito su una <b>Ricaricabile 3</b>, ti verrà accreditato direttamente sulla ricaricabile.",
                    "portability": "Ti ricordiamo che il passaggio del tuo numero sulla SIM Tre avverrà alla data presunta indicata nel modulo di richiesta. Tuttavia si specifica che: 'La data di attivazione del Servizio MNP potrà in ogni caso differire da quella indicata ed, in particolare, essere anticipata, subire ritardi o essere rifiutata per le cause descritte nell'Art. 4 del Regolamento di Servizio MNP'.",
                    "sms": "Alla ricezione dell'SMS di conferma di avvenuta migrazione del numero ti ricordiamo che per il corretto funzionamento in rete occorre spegnere e riaccendere il telefono.",
                    "title": "Portabilità",
                    "transfer": "Se hai anche richiesto il trasferimento del credito residuo che avevi con il precedente operatore, ti verrà decurtato il costo di trasferimento applicato dall'operatore cedente. L'operazione avverrà entro 72 ore dall' avvenuta portabilità e riceverai conferma tramite SMS.",
                    "transfer_info": "Il trasferimento del credito avverrà in un arco temporale che va dai 3 agli 8 giorni successivi all'esito positivo della portabilità."
                  },
                  "my3": {
                    "login": "<ul><li>Quando sei sotto rete 3 clicca su “Login automatico” senza digitare le credenziali;</li><li>Quando sei in Wi-Fi, registrati e memorizza la password, così non dovrai più inserirla in seguito.</li></ul>",
                    "login_alternative": "Se preferisci utilizzare un PC/Mac/Tablet puoi accedere alle stesse informazioni accedendo <b>all'Area Clienti</b> dal tuo web browser digitando",
                    "login_help": "Potrai accedere all'app My3 trascorse 24 ore dal messaggio SMS di conferma di abilitazione dell'offerta.",
                    "login_link": "https://areaclienti3.tre.it/133/",
                    "login_title": "Accedere all'app è semplicissimo",
                    "need_help": "E se hai bisogno di aiuto, l'assistente digitale <b>Trelpy</b> sarà a tua disposizione.",
                    "text": "<ul><li>Visualizzare l'<b>offerta attiva e i tuoi costi</b></li><li>Controllare i tuoi <b>consumi</b>, il tuo credito e le tue soglie</li><li><b>Ricaricare</b> in un attimo il numero che vuoi</li><li><b>Conoscere</b> le opzioni da aggiungere alla tua offerta</li><li>Verificare PIN e PUK </li><li><b>Associare</b> le tue SIM per gestire tutte le linee mobili da un unico accesso</li></ul>",
                    "title": "Con My3 puoi:"
                  },
                  "title": "Lorem ipsum dolor sit amet dolor lorem sit amet",
                  "under_control": {
                    "description": "<b>My3 è l'app gratuita</b> pensata per gestire i tuoi numeri dal tuo Smartphone ed essere sempre aggiornato sulle ultime novità di 3.",
                    "title": "Tutto sotto controllo"
                  }
                }
              },
              "untied": {
                "no_mnp": {
                  "my3": {
                    "login": "<ul><li>Quando sei sotto rete 3 clicca su “Login automatico” senza digitare le credenziali;</li><li>Quando sei in Wi-Fi, registrati e memorizza la password, così non dovrai più inserirla in seguito.</li></ul>",
                    "login_alternative": "Se preferisci utilizzare un PC/Mac/Tablet puoi accedere alle stesse informazioni accedendo <b>all'Area Clienti</b> dal tuo web browser digitando",
                    "login_help": "Potrai accedere all'app My3 trascorse 24 ore dal messaggio SMS di conferma di abilitazione dell'offerta.",
                    "login_link": "https://areaclienti3.tre.it/133/",
                    "login_title": "Accedere all'app è semplicissimo",
                    "need_help": "E se hai bisogno di aiuto, l'assistente digitale <b>Trelpy</b> sarà a tua disposizione.",
                    "text": "<ul><li>Visualizzare l'<b>offerta attiva e i tuoi costi</b></li><li>Controllare i tuoi <b>consumi</b>, le tue soglie e consultare le <b>fatture</b></li><li><b>Ricaricare</b> in un attimo il numero che vuoi</li><li><b>Conoscere</b> le opzioni da aggiungere alla tua offerta</li><li>Verificare PIN e PUK </li><li><b>Associare</b> le tue SIM per gestire tutte le linee mobili da un unico accesso</li></ul>",
                    "title": "Con My3 puoi:"
                  },
                  "title": "Lorem ipsum dolor sit amet dolor lorem sit amet",
                  "under_control": {
                    "description": "<b>My3 è l'app gratuita</b> pensata per gestire i tuoi numeri dal tuo Smartphone ed essere sempre aggiornato sulle ultime novità di 3.",
                    "title": "Tutto sotto controllo"
                  }
                },
                "port_in": {
                  "description": {
                    "portability": "Ti ricordiamo che il passaggio del tuo numero sulla SIM Tre avverrà alla data presunta indicata nel modulo di richiesta. Tuttavia si specifica che: 'La data di attivazione del Servizio MNP potrà in ogni caso differire da quella indicata ed, in particolare, essere anticipata, subire ritardi o essere rifiutata per le cause descritte nell'Art. 4 del Regolamento di Servizio MNP'.",
                    "sms": "Alla ricezione dell'SMS di conferma di avvenuta migrazione del numero ti ricordiamo che per il corretto funzionamento in rete occorre spegnere e riaccendere il telefono.",
                    "title": "Portabilità",
                    "transfer": "Se hai anche richiesto il trasferimento del credito residuo che avevi con il precedente operatore, ti verrà decurtato il costo di trasferimento applicato dall'operatore cedente. L'operazione avverrà entro 72 ore dall' avvenuta portabilità e riceverai conferma tramite SMS."
                  },
                  "my3": {
                    "login": "<ul><li>Quando sei sotto rete 3 clicca su “Login automatico” senza digitare le credenziali;</li><li>Quando sei in Wi-Fi, registrati e memorizza la password, così non dovrai più inserirla in seguito.</li></ul>",
                    "login_alternative": "Se preferisci utilizzare un PC/Mac/Tablet puoi accedere alle stesse informazioni accedendo <b>all'Area Clienti</b> dal tuo web browser digitando",
                    "login_help": "Potrai accedere all'app My3 trascorse 24 ore dal messaggio SMS di conferma di abilitazione dell'offerta.",
                    "login_link": "https://areaclienti3.tre.it/133/",
                    "login_title": "Accedere all'app è semplicissimo",
                    "need_help": "E se hai bisogno di aiuto, l'assistente digitale <b>Trelpy</b> sarà a tua disposizione.",
                    "text": "<ul><li>Visualizzare l'<b>offerta attiva e i tuoi costi</b></li><li>Controllare i tuoi <b>consumi</b>, le tue soglie e consultare le <b>fatture</b></li><li><b>Ricaricare</b> in un attimo il numero che vuoi</li><li><b>Conoscere</b> le opzioni da aggiungere alla tua offerta</li><li>Verificare PIN e PUK </li><li><b>Associare</b> le tue SIM per gestire tutte le linee mobili da un unico accesso</li></ul>",
                    "title": "Con My3 puoi:"
                  },
                  "title": "Lorem ipsum dolor sit amet dolor lorem sit amet",
                  "under_control": {
                    "description": "<b>My3 è l'app gratuita</b> pensata per gestire i tuoi numeri dal tuo Smartphone ed essere sempre aggiornato sulle ultime novità di 3.",
                    "title": "Tutto sotto controllo"
                  }
                }
              }
            }
          },
          "windtre": {
            "no-tax": {
              "bill": {
                "description": "Il Conto Telefonico Wind prevede un periodo di fatturazione mensile. Puoi visualizzare le fatture emesse registrandoti all’Area Clienti su Wind.it o <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>scaricando l’App WINDTRE</a> dallo Store sul tuo smartphone.<br/>Se vuoi ricevere il conto telefonico direttamente sulla tua e-mail, azzerando il costo di spedizione e contribuendo ad una scelta ecocompatibile, attiva gratuitamente il servizio e-conto. Puoi farlo sia attraverso l’Area Clienti (Sezione Il Tuo Conto Telefonico/Gestisci il Tuo Conto) che attraverso l’App WINDTRE (Dati pagamento / Gestisci il tuo Conto). ",
                "title": "Fatturazione"
              },
              "block": {
                "description": "Il blocco si applica a tutte le offerte Wind Family e alle offerte dati attivate presso i negozi Wind. Sono esclusi dal blocco le seguenti funzionalità: i servizi SMS bancari, gli acquisti da Google Play e Windows Phone e il Mobile Ticketing.<br/>Per tutti i dettagli visita la pagina <a class='selfcare-help__link' href='www.windtre.it/assistenza-servizi'>www.windtre.it/assistenza-servizi</a></br>Per poter procedere alla disattivazione del blocco, puoi contattare il 155.",
                "title": "Blocco servizi a sovrapprezzo:"
              },
              "call": {
                "description": "Le SIM Ricaricabili che compongono la tua offerta sono automaticamente abilitate alle chiamate internazionali e al roaming internazionale.<br/>Per le connessioni effettuate dalla SIM Dati Abbonamento, se si sceglie di pagare con carta di credito il traffico internazionale è subito abilitato. Se invece si sceglie di pagare con addebito su conto corrente bancario, l’abilitazione avverrà dopo il pagamento del primo conto telefonico. ",
                "title": "Chiamate internazionali"
              },
              "connection": {
                "description": "In riferimento all’offerta con Giga inclusi, prima di connetterti ad Internet verifica che l’offerta richiesta risulti attiva. Se hai ricevuto il messaggio di conferma attivazione prosegui, altrimenti attendi la ricezione del messaggio. Per configurare la connessione internet sul tuo smartphone ti basta seguire le istruzioni presenti sulla seguente pagina <a class='selfcare-help__link' href='https://www.windtre.it/configurazione-smartphone'>www.windtre.it/assistenza-servizi</a>",
                "title": "Connessione Internet"
              },
              "control": {
                "description": "Con l’App WINDTRE puoi verificare il tuo credito, i Minuti, gli SMS e i Giga residui e tanto altro ancora. In più, puoi configurare il widget sul tuo smartphone per visualizzare i contatori ed avere tutti i tuoi consumi sotto controllo!<br/>Per conoscere tutti i dettagli sull’offerta sottoscritta e per avere informazioni sul dettaglio traffico e sui servizi utilizzati e sulla tua modalità di pagamento, <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>scarica l’App My Wind</a> dallo Store o registrati all’Area Clienti su Wind.it . ",
                "title": "Tutto sotto controllo"
              },
              "mnp": {
                "description": "Se hai chiesto di portare il numero di altro gestore sulla SIM Wind ti ricordiamo che il passaggio avverrà entro 2 GG lavorativi dalla data di attivazione della SIM.<br/>Se hai richiesto un’offerta con Minuti, Giga o SMS inclusi attendi di ricevere l’SMS con la conferma dell’avvenuta attivazione.  Il costo di chiamate, SMS e connessioni internet sarà a pagamento fino a tale messaggio di conferma. Nei prossimi giorni riceverai anche alcuni SMS che ti informeranno sull’avanzamento della richiesta di portabilità del numero e poi un ultimo SMS a portabilità avvenuta.",
                "title": "MNP",
                "transfer": "Se hai richiesto il trasferimento del credito residuo che avevi con il precedente operatore, a questo verrà decurtato il costo di trasferimento applicato dall’operatore cedente ed avverrà entro le 72 ore dalla avvenuta portabilità. Anche per questa attività riceverai un SMS di conferma."
              },
              "offer": {
                "description": "L’offerta da te sottoscritta prevede l’attivazione di una SIM Dati in Abbonamento con Giga condivisibili e di una SIM Voce Ricaricabile.<br/>Per usufruire del bonus previsto dall’offerta da te sottoscritta è necessario avere sulla SIM Ricaricabile un credito diverso da 0€.",
                "giga": "I GIGA disponibili sulla SIM Dati sono condivisibili gratuitamente con: fino ad un massimo di 3 SIM attive sullo stesso conto telefonico dell’offerta Wind Family e ulteriori 4 SIM ricaricabili con offerte All Inclusive, anche nella versione Easy Pay e Call Your Country con minuti inclusi e anche se intestate ad un altro cliente Wind.",
                "title": "Struttura l'Offerta",
                "windfamily": "Con Wind Family hai il vantaggio di avere una SIM Ricaricabile, che prevede l’utilizzo di un credito telefonico e un costo mensile esposto nel conto telefonico dell’offerta Wind Family. Tutto il traffico e i costi dei servizi non inclusi nell’offerta saranno addebitati sul credito residuo."
              },
              "topup": {
                "description": "Sulla tua SIM Ricaricabile al momento dell’attivazione è stato incluso anche il servizio di Ricarica Automatica che ti consentirà di non esaurire mai il tuo credito residuo. Gli importi delle ricariche verranno addebitati direttamente sul tuo Conto telefonico. Per maggiori dettagli consulta l’Area Clienti su Wind.it o <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>scarica l’App WINDTRE</a> dallo Store del tuo smartphone.\"",
                "title": "Ricarica Automatica"
              }
            },
            "postpaid": {
              "post": {
                "bill": {
                  "description": "Il Conto telefonico Wind prevede un periodo di fatturazione mensile. Puoi visualizzare le fatture emesse, registrandoti all’Area Clienti su Wind.it o <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>scaricando l’App WINDTRE</a> dallo Store sul tuo smartphone.<br/>Se vuoi ricevere il conto telefonico direttamente sulla tua e-mail, azzerando il costo di spedizione e contribuendo ad una scelta ecocompatibile, attiva gratuitamente il servizio e-conto. Puoi farlo sia attraverso l’Area Clienti (Sezione Il Tuo Conto Telefonico/Gestisci il Tuo Conto) che con l’App WINDTRE (Dati pagamento / Gestisci il tuo Conto).",
                  "title": "Fatturazione"
                },
                "call": {
                  "description": "Per poter effettuare chiamate internazionali o accedere al roaming internazionale quando sei all’estero, occorre che la SIM sia abilitata.<br/>L’abilitazione viene effettuata automaticamente in base alla formula di pagamento che hai scelto: <ul><li>Con l’addebito su carta di credito, l'abilitazione del servizio è immediata e gratuita.</li><li>Con l’addebito su conto corrente bancario, l'abilitazione è gratuita ed avviene automaticamente dopo il primo addebito del conto telefonico andato a buon fine.</li></ul>",
                  "title": "Chiamate internazionali"
                },
                "connection": {
                  "description": "Se hai richiesto un’offerta con Giga inclusi, prima di connetterti ad Internet, verifica che l’offerta richiesta risulti attiva. Se hai ricevuto il messaggio di conferma dell’attivazione, prosegui, altrimenti attendi la ricezione del messaggio. Per poter configurare il tuo smartphone per la navigazione internet, vai sulla pagina <a class='selfcare-help__link' href='www.windtre.it/assistenza-servizi'>www.windtre.it/assistenza-servizi</a>",
                  "title": "Connessione Internet"
                },
                "control": {
                  "description": "Con l’App WINDTRE puoi verificare il tuo credito, i Minuti, gli SMS e i Giga residui e tanto altro ancora. In più, puoi configurare il widget sul tuo smartphone per visualizzare i contatori ed avere tutti i tuoi consumi sotto controllo!<br/>Per conoscere tutti i dettagli sull’offerta da te sottoscritta e per avere informazioni sul dettaglio del traffico, sui servizi utilizzati e sulla tua modalità di pagamento, <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>scarica l’App WINDTRE</a> dallo o registrati all’Area Clienti su Wind.it ",
                  "title": "Tutto sotto controllo"
                }
              }
            },
            "prepaid": {
              "mnp": {
                "connection": {
                  "description": "Se hai richiesto un’offerta con Giga inclusi, prima di connetterti ad internet verifica che l’offerta risulti attiva. Prosegui solo se hai ricevuto il messaggio di conferma dell’attivazione, altrimenti attendi la ricezione del messaggio.",
                  "title": "Connessione Internet"
                },
                "control": {
                  "description": "Con l’App WINDTRE puoi controllare il tuo credito residuo, Minuti, SMS e Giga rimanenti, e visualizzare le offerte a te dedicate. In più puoi configurare il widget per visualizzare i contatori ed avere tutti i tuoi consumi sotto controllo!<br/>Per conoscere tutti i dettagli della tua offerta , il traffico effettuato e i servizi utilizzati, <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>scarica l’App WINDTRE</a> dallo Store",
                  "title": "Tutto sotto controllo"
                },
                "credit": {
                  "description": "<a class='selfcare-help__link' href='https://www.windtre.it/assistenza-servizi/come-installare-configurare-modem-windtre/'>Scarica l’App WINDTRE</a> e, istallando il widget sul tuo smartphone, potrai verificare il tuo credito residuo.  Con WINDTRE, ricarichi in totale sicurezza il tuo numero o quello dei tuoi amici quando e dove vuoi, scegliendo il tuo metodo di pagamento preferito tra Carta di Credito, PayPal.",
                  "title": "Credito Residuo"
                },
                "description": {
                  "mnp": "Se hai richiesto la portabilità del numero, ti ricordiamo che il passaggio avverrà entro 2 giorni lavorativi dalla data di attivazione della tua nuova SIM Wind.<br/>Nei giorni successivi verrai informato tramite SMS sull’avanzamento della procedura di portabilità del numero.<br/>Se hai richiesto un’offerta con Minuti, Giga o SMS inclusi, attendi di ricevere l’SMS con la conferma dell’avvenuta attivazione in quanto il costo di chiamate, SMS e connessione internet sarà a pagamento fino alla ricezione di tale messaggio.",
                  "sms": "Se hai richiesto il trasferimento del credito residuo presente sulla SIM di provenienza, a questo importo verrà decurtato il costo di trasferimento, applicato dall’operatore cedente, entro 72 ore dalla avvenuta portabilità.<br/>A trasferimento avvenuto riceverai un SMS di conferma.",
                  "title": "MNP"
                }
              },
              "no-mnp": {
                "activation": {
                  "description": "Ti ricordiamo che il passaggio avverrà entro 2 giorni lavorativi dalla data di attivazione della tua nuova SIM Wind. Nei giorni successivi verrai informato tramite SMS sull’avanzamento della procedura di portabilità del numero.",
                  "title": "Passaggio a Wind"
                },
                "activation_info": null,
                "connection": {
                  "description": "Se hai richiesto un’offerta con Giga inclusi, prima di connetterti ad Internet, verifica che l’offerta richiesta risulti attiva. Se hai ricevuto il messaggio di conferma dell’attivazione, prosegui, altrimenti attendi la ricezione del messaggio. Per poter configurare il tuo smartphone per la navigazione internet, vai sulla pagina <a class='selfcare-help__link' href='www.windtre.it/assistenza-servizi'>www.windtre.it/assistenza-servizi</a>",
                  "title": "Connessione Internet"
                },
                "control": {
                  "description": "Con l’App WINDTRE puoi verificare il tuo credito, i Minuti, gli SMS e i Giga residui e tanto altro ancora. In più, puoi configurare il widget sul tuo smartphone per visualizzare i contatori ed avere tutti i tuoi consumi sotto controllo! Per conoscere tutti i dettagli sull’offerta sottoscritta e per avere informazioni sul dettaglio traffico e sui servizi utilizzati, <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>scarica l’App My Wind</a> dallo Store o registrati all’Area Clienti su Wind.it . ",
                  "title": "Tutto sotto controllo"
                },
                "credit": {
                  "description": "<a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>Scarica l’App WINDTRE</a> e, istallando il widget sul tuo smartphone, potrai verificare il credito disponibile, ogni volta che vuoi.<br/>Attraverso l’app, ricarichi in totale sicurezza il tuo numero o quello dei tuoi amici quando e dove vuoi, scegliendo il metodo di pagamento preferito tra Carta di Credito e PayPal. Inoltre, puoi visualizzare il credito direttamente sul display del tuo telefonino anche digitando *123# seguito da INVIO.",
                  "title": "Credito Residuo"
                },
                "existing_line": {
                  "description": "Se hai richiesto la Fibra sul tuo numero di telefono, la Fibra sarà attivata in circa 20 giorni, inizialmente su di un numero provvisorio che potrai utilizzare da subito e che ti verrà comunicato con un SMS.<br/> Successivamente si procederà al passaggio del tuo numero che impiegherà circa 15 giorni e varia in base all'operatore telefonico di provenienza.<br/>In attesa che avvenga il passaggio del tuo numero, oltre a navigare, potrai chiamare:<ul><li>da subito con il numero provvisorio collegando il telefono al modem;</li><li>oppure continuare ad usare il tuo numero con il precedente operatore lasciando il telefono collegato alla presa telefonica</li></ul> Ti avviseremo con un SMS quando il tuo numero sostituirà quello provvisorio. Da quel momento potrai utilizzare il tuo numero esclusivamente dal telefono collegato al modem.",
                  "title": "Attivazione su linea esistente"
                },
                "new_line": {
                  "description": "Se hai richiesto la Fibra su una nuova linea telefonica, l'attivazione impiegherà circa 20 giorni ed il nuovo numero ti verrà comunicato con un SMS",
                  "title": "Attivazione su nuova linea"
                }
              }
            },
            "tied": {
              "connection": {
                "description": "Se hai richiesto un’offerta con Giga inclusi, prima di connetterti ad Internet, verifica che l’offerta richiesta risulti attiva. Se hai ricevuto il messaggio di conferma dell’attivazione, prosegui, altrimenti attendi la ricezione del messaggio. Per poter configurare il tuo smartphone per la navigazione internet, vai sulla pagina <a class='selfcare-help__link' href='www.windtre.it/assistenza-servizi'>www.windtre.it/assistenza-servizi</a>",
                "title": "Connessione Internet"
              },
              "credit": {
                "description": "<a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>Scarica l’App WINDTRE</a> e, istallando il widget, potrai verificare il credito residuo, ogni volta che vuoi.<br/>Inoltre, attraverso l’App, ricarichi in totale sicurezza il tuo numero o quello dei tuoi amici quando e dove vuoi, scegliendo il tuo metodo di pagamento preferito tra Carta di Credito,  PayPal e Conto Telefonico.<br/>Inoltre, puoi visualizzare il credito direttamente sul display del tuo telefonino anche digitando *123# seguito da INVIO. ",
                "title": "Credito Residuo"
              },
              "mnp": {
                "description": "Se hai richiesto di trasferire il tuo vecchio numero di altro gestore sulla tua nuova SIM Wind, ti ricordiamo che il passaggio avverrà entro 2 giorni lavorativi dalla data di attivazione della SIM.<br/>Se hai richiesto un’offerta con Minuti, Giga o SMS inclusi, attendi di ricevere l’SMS con la conferma dell’avvenuta attivazione.  Il costo di chiamate, SMS e connessioni internet sarà a pagamento fino a tale messaggio di conferma. Nei giorni successivi riceverai anche alcuni SMS che ti informeranno sull’avanzamento della richiesta di portabilità del numero e poi un ultimo SMS a portabilità avvenuta.",
                "title": "MNP",
                "transfer": "Se hai richiesto il trasferimento del credito residuo che avevi con il precedente operatore, a questo verrà decurtato il costo di trasferimento applicato dall’operatore cedente ed avverrà entro le 72 ore dalla avvenuta portabilità. Riceverai un SMS di conferma al termine dell’attività."
              },
              "offer": {
                "description": "Con l’offerta Easy Pay da te sottoscritta, hai il vantaggio di avere una SIM Ricaricabile, che prevede l’utilizzo di un credito telefonico, e l’addebito del costo mensile direttamente sulla tua carta di credito o sul tuo conto corrente bancario.<br/>Tutto il traffico e i costi dei servizi non inclusi nell’offerta saranno addebitati sul credito residuo.<br/>Per conoscere tutti i dettagli sull’offerta da te sottoscritta e per avere informazioni sul dettaglio del traffico e sui servizi utilizzati, <a class='selfcare-help__link' href='https://www.windtre.it/app-windtre'>scarica l’App WINDTRE</a> dallo Store o registrati all’Area Clienti su Wind.it .<br/>Con l’App MyWind puoi controllare il tuo credito residuo, Minuti, SMS e Giga, visualizzare i bonus residui e tanto altro ancora. In più, puoi configurare il Widget sul tuo smartphone per visualizzare i contatori ed avere tutti i tuoi consumi sotto controllo!",
                "title": "Struttura l'Offerta"
              },
              "payment": {
                "description": "Puoi consultare la tua modalità di pagamento attraverso l’Area Clienti, sezione Easy Pay. Per registrarti accedi dall’home page di Wind.it <a class='selfcare-help__link' href='http://areaclienti.dev.windtre.it/'>http://areaclienti.dev.windtre.it/</a>",
                "title": "Modalità di pagamento"
              },
              "topup": {
                "description": "Con il servizio di Ricarica Automatica, attivabile su App WINDTRE, sei sicuro di non esaurire mai il tuo credito residuo, ricaricando automaticamente la tua SIM tramite la modalità di pagamento indicata in fase di sottoscrizione dell’offerta Ricaricabile Easy Pay. È possibile scegliere Ricarica Automatica a Soglia o a Tempo.",
                "title": "Ricarica Automatica:"
              }
            }
          }
        }
      },
      "home": {
        "fixed": "Fisso",
        "mobile": {
          "tre": "Mobile Tre",
          "windtre": "Mobile"
        },
        "title": "SelfCare"
      },
      "index": {
        "fttc_ull": "/help/fisso/fttc-ull",
        "fttc_ull_la": "/help/fisso/fttc-ull-la",
        "fttc_ull_la_fp": "/help/fisso/fttc-ull-la-fp",
        "fttc_ull_lna": "/help/fisso/fttc-ull-lna",
        "fttc_ull_lna_np": "/help/fisso/fttc-ull-lna-np",
        "ftth": "/help/fisso/ftth",
        "ftth-fp": "/help/fisso/ftth-fp",
        "ftth_aree": "/help/fisso/ftth-attivo",
        "ftth_ftth": "/help/fisso/ftth-ftth",
        "ftth_onestep": "/help/fisso/ftth-onestep",
        "ftth_ull_iso": "/help/fisso/ftth-ull-iso",
        "ftth_ull_iso_fp": "/help/fisso/ftth-ull-iso-fp",
        "ftth_ull_iso_onestep": "/help/fisso/ftth-ull-iso-onestep",
        "fwa": "/help/fisso/fwa"
      },
      "modals": {
        "technologies": {
          "adsl": {
            "description": "Il collegamento interamente in rame (doppino telefonico tradizionale).",
            "speed": "Fino a 20 Mb/s in download (fino a 7 Mb/s dove non disponibile) e 1 Mb/s in upload.",
            "text": "(Asymmetric Digital Subscriber Line)",
            "title": "ADSL"
          },
          "dedicated_page": "pagina dedicata",
          "description": "Descrizione",
          "details": "Per ulteriori dettagli vai alla",
          "fttc": {
            "description": "La Fibra ottica che arriva fino all'armadio in strada (cabinet) vicino all'abitazione del cliente. Il collegamento finale verso l'abitazione del cliente è in rame.",
            "info": "*FTTC è una casistica particolare della tecnologia FTTN (Fiber to the Node) e arriva fino all'armadio di strada, più vicino quindi all'abitazione del cliente, anzichè fino al Nodo.",
            "speed": "Fino a 200 Mb/s (fino a 100 Mb/s dove non disponibile) in download e 20 Mb/s in upload.",
            "text": "(Fiber To The Cabinet)",
            "title": "FTTC"
          },
          "ftth": {
            "cities": "Ancona, Beinasco, Bari, Bologna, Bresso, Buccinasco, Cagliari, Catania, Cinisello Balsamo, Cornaredo, Corsico, Genova, Grugliasco, Matera, Milano, Moncalieri, Napoli, Nichelino, Opera, Orbassano, Padova, Palermo, Perugia, Pescara, Pioltello, Pisa, Prato, Rozzano, Sassari, Segrate, San Donato Milanese, San Giuliano Milanese, Sesto San Giovanni, Settimo Milanese, Settimo Torinese, Torino, Treviso, Trezzano sul Naviglio, Udine, Venaria Reale, Venezia, Vimodrone.",
            "description": "La Fibra ottica che arriva direttamente all'abitazione del cliente.",
            "speed": "Fino a 1000 Mb/s in download e 100 Mb/s in upload by Open Fiber.",
            "text": "(Fiber To The Home)",
            "title": "FTTH"
          },
          "reached_cities": "COMUNI RAGGIUNTI DA TECNOLOGIA FTTH",
          "speed": "Velocità",
          "technology": "Tecnologia",
          "title": "Tecnologie di connessione"
        }
      },
      "privacy": "Privacy",
      "vat": "Partita IVA: 13378520152",
      "version": "v. 1.5.5 onebrand 22/06/2023"
    },
    "widi-uikit": {
      "card": {
        "discover": "Scopri"
      },
      "errors": {
        "validation": {
          "invalid": "Campo non valido",
          "required": "Campo obbligatorio"
        }
      },
      "submit": {
        "continue": "Continua"
      },
      "tabs": {
        "toggle": {
          "show": "Mostra"
        }
      }
    }
  };
  _exports.default = _default;
});