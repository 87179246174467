define("@dwind/selfcare/templates/components/widi-hero-background", ["exports", "@dwind/widi-uikit/templates/components/widi-hero-background"], function (_exports, _widiHeroBackground) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiHeroBackground.default;
    }
  });
});