define("@dwind/selfcare/templates/components/selfcare-help-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JmmRMnBY",
    "block": "{\"symbols\":[\"step\",\"index\",\"@steps\"],\"statements\":[[4,\"each\",[[22,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"selfcare-help-steps__container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"selfcare-help-steps__counter\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"count\"],[9],[1,[27,\"add\",[[22,2,[]],1],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"selfcare-help-steps__rightContainer\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"selfcare-help-steps__rightContainer__title\"],[9],[0,\"\\n\"],[0,\"        \"],[1,[22,1,[\"title\"]],true],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"selfcare-help-steps__rightContainer__description\"],[9],[0,\"\\n\"],[0,\"        \"],[1,[22,1,[\"description\"]],true],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/selfcare/templates/components/selfcare-help-steps.hbs"
    }
  });

  _exports.default = _default;
});