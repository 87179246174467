define("@dwind/selfcare/adapters/application", ["exports", "ember-data/adapters/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    config: Ember.inject.service(),
    host: Ember.computed.readOnly('config.apiHost'),
    namespace: 'steppenwolf/api/v1/ecommerce',
    pathForType: function pathForType(type) {
      return type;
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);

      if (url.charAt(url.length - 1) !== '/') {
        url += '/';
      }

      return url;
    }
  });

  _exports.default = _default;
});