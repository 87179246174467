define("@dwind/selfcare/routes/help/mobile/prepaid", ["exports", "@dwind/selfcare/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    intl: Ember.inject.service(),
    selfcareChannel: Ember.inject.service(),
    brand: Ember.computed.readOnly('selfcareChannel.brand'),
    beforeModel: function beforeModel() {
      this.transitionTo("help.mobile.".concat(this.brand, ".prepaid"));
    },
    model: function model() {
      var temporaryBrand = '';
      return {
        activationInfo: [{
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.activation_info.new_line.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.activation_info.new_line.description"))
        }, {
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.activation_info.existing_line.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.activation_info.existing_line.description"))
        }],
        navigationFaq: [{
          image: '/assets/@dwind/selfcare/images/icons/ico_sempre-connesso.svg',
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.navigation_faq.wifi_connection.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.navigation_faq.wifi_connection.description"))
        }, {
          image: '/assets/@dwind/selfcare/images/icons/ico_wifi_windtre.svg',
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.navigation_faq.better_wifi.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.navigation_faq.better_wifi.description"))
        }, {
          image: '/assets/@dwind/selfcare/images/icons/ico_speed_windtre.svg',
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.navigation_faq.optimal_speed.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.navigation_faq.optimal_speed.description"))
        }],
        nextSteps: [{
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.next_step.appointment.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.next_step.appointment.description"))
        }, {
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.next_step.technitian.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.next_step.technitian.description"))
        }, {
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.next_step.installation.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.next_step.installation.description"))
        }, {
          title: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.next_step.configuration.title")),
          description: this.intl.t("selfcare.help.fixed".concat(temporaryBrand, ".ftth.next_step.configuration.description"))
        }]
      };
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var data = Ember.getProperties(model, 'activationInfo', 'navigationFaq', 'nextSteps');
      Ember.setProperties(controller, data);
    }
  });

  _exports.default = _default;
});